import { Button } from "@mui/material";
import { styled as muiStyled } from "@mui/system";
import React from "react";
import styled from "styled-components";

const ButtonComponent = ({ properties, style }: any) => {
  const defaultProps = {
    href: properties?.url,
    target: properties?.targetBlank ? "_blank" : "",
  };

  const stylDiv = {
    paddingTop: `${style.paddingTop}px`,
    paddingRight: `${style.paddingRight}px`,
    paddingBottom: `${style.paddingBottom}px`,
    paddingLeft: `${style.paddingLeft}px`,
    marginTop: `${style.marginTop}px`,
    marginRight: `${style.marginRight}px`,
    marginBottom: `${style.marginBottom}px`,
    marginLeft: `${style.marginLeft}px`,
    textAlign: style.buttonAlign,
    zIndex: style.zIndex,
    border: `${style.borderSize}px ${style.borderType} ${style.borderColor}`,
    borderRadius: style.borderRadius,
    backgroundColor: style.backgroundColor,
  };

  const stylBtn = {
    lineHeight: `${style.lineHeight}px`,
    color: `${style.color}`,
    fontSize: `${style.fontSize}px`,
    fontFamily: style.fontFamily,
    backgroundColor: `${style.buttonBackgroundColor}`,
    borderRadius: `${style.buttonBorderRadius}px`,
    width: `${style.buttonWidth}%`,
  };

  const Button = React.memo(() => (
    <button className="pure-material-button-contained" style={{ ...stylBtn }}>
      {properties?.label}
    </button>
  ));
  return (
    <div style={{ ...stylDiv, position: "relative" }}>
      {properties?.url !== "" ? (
        <a {...defaultProps}>
          <Button />
        </a>
      ) : (
        <Button />
      )}
    </div>
  );
};

export default ButtonComponent;
