import { ImageTag } from "./Image";

const ImageTextComponent = ({ style, properties }: any) => {
  const WrapDivStyle = {
    paddingTop: `${style.paddingTop}px`,
    paddingRight: `${style.paddingRight}px`,
    paddingBottom: `${style.paddingBottom}px`,
    paddingLeft: `${style.paddingLeft}px`,
    marginTop: `${style.marginTop}px`,
    marginRight: `${style.marginRight}px`,
    marginBottom: `${style.marginBottom}px`,
    marginLeft: `${style.marginLeft}px`,
    color: style.color,
    lineHeight: `${style.lineHeight}px`,
    textAlign: style.textAlign,
    fontSize: `${style.fontSize}px`,
    fontWeight: style.fontWeight,
    fontFamily: style.fontFamily,
    display: "flex",
    gap: `${style.gap}px`,
    flexDirection: style.flexDirection,
    alignItems: style.flexAlign,
    backgroundColor: style.backgroundColor,
    border: `${style.borderSize}px ${style.borderType} ${style.borderColor}`,
    zIndex: style.zIndex,
  };

  const ImageDivStyle = {
    width: `${style.imgColumnWidth}%`,
    textAlign: style.imgAlign,
  };

  const ImageStyle = {
    width: `${style.imgSize}%`,
  };

  const TextDivStyle = { width: `${style.textColumnWidth}%` };

  return (
    <div style={{ ...WrapDivStyle, position: "relative" }}>
      <div style={{ ...ImageDivStyle }}>
        <ImageTag
          styleData={{ ...ImageStyle }}
          src={properties?.src || "/images/add-image.png"}
          altText={properties?.altText}
        />
      </div>
      <div style={{ ...TextDivStyle }}>{properties?.text}</div>
    </div>
  );
};

export default ImageTextComponent;
