import { axiosGet, axiosPost } from "../shared/axiosService";

export type AxiosResponse = {
  data: any[];
  error: any;
};

export const getUserProfile = async () => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosGet("user/profile");
    if (res.data.status) {
      response.data = res.data.profile;
    } else {
      response.error = res.data.message;
    }
  } catch (error) {
    console.error(error);
    response.error = error;
  }

  return response;
};

export const saveUserProfile = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("user/save-profile", body);
    if (res.data.status) {
      response.data = res.data.profile;
    } else {
      response.error = res.data.message;
    }
  } catch (error) {
    console.error(error);
    response.error = error;
  }

  return response;
};
