import { validatePhoneNumber } from "../shared/utils";

export const generateContactFromString = (value: string) => {
  const valuesString = value.substring(0, value.lastIndexOf("\n"));
  const contanctValues = valuesString.split("\n");
  const contactDataArray: any[] = [];
  contanctValues.forEach((contactString: string, index: number) => {
    const values = contactString.split(",");
    if (values.length > 2)
      contactDataArray.push({
        id: index + 1,
        firstName: values[0],
        lastName: values[1],
        email: values[2],
        phone: values.length > 3 ? values[3] : null,
      });
  });
  return contactDataArray;
};

export const validateContacts = (contactData: any[]) => {
  const isInValid = contactData.some((item: any) => {
    return (
      !item.firstName ||
      !item.lastName ||
      !(item.email && validateEmail(item.email)) ||
      !(
        item.phone &&
        validatePhoneNumber(item.phone?.toString().replace("+91", ""))
      )
    );
  });

  return !isInValid;
};

function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
