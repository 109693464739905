import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import "./Header.scss";
import { isLoggedIn } from "../shared/utils";
import { auth } from "../Login/firebase";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Person3OutlinedIcon from "@mui/icons-material/Person3Outlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ProfileMenu from "./ProfileMenu";
import NotificationMenu from "./NotificationMenu";
import SupportMenu from "./SupportMenu";

export type HeaderProps = {
  title: string;
  userData: any;
};
const Header = ({ title, userData }: HeaderProps) => {
  let navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (route: any) => {
    setAnchorElUser(null);
    if (typeof route === "string") {
      if (route === "logout") {
        onLogout();
        return;
      }

      return navigate(`/${route}`);
    }
  };

  const [anchorElNotification, setAnchorElNotification] =
    React.useState<null | HTMLElement>(null);
  const handleNotificationOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleNotificationClose = (route: any) => {
    setAnchorElNotification(null);
    if (typeof route === "string") {
      if (route === "logout") {
        onLogout();
        return;
      }

      return navigate(`/${route}`);
    }
  };

  const [anchorElSupport, setAnchorElSupport] =
    React.useState<null | HTMLElement>(null);
  const handleSupportOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSupport(event.currentTarget);
  };

  const handleSupportClose = (route: any) => {
    setAnchorElSupport(null);
    if (typeof route === "string") {
      if (route === "logout") {
        onLogout();
        return;
      }

      return navigate(`/${route}`);
    }
  };
  const onLogout = () => {
    auth.signOut();
    localStorage.clear();
    window.location.reload();
  };

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      className="header"
    >
      <Container className="app-header">
        <Box sx={{ flexGrow: 0 }}>
          <img
            src="/images/logo.png"
            alt="esender"
            className="logo"
            onClick={() => navigate("/")}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <div className="header-title">{title}</div>
        </Box>
        {isLoggedIn() && (
          <>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Notifications">
                <IconButton onClick={handleNotificationOpen}>
                  <NotificationsNoneOutlinedIcon />
                </IconButton>
              </Tooltip>
              <NotificationMenu
                anchorElNotification={anchorElNotification}
                handleNotificationClose={handleNotificationClose}
              />
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Help">
                <IconButton onClick={handleSupportOpen} sx={{ ml: "10px" }}>
                  <HelpOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
              <SupportMenu
                anchorElSupport={anchorElSupport}
                handleSupportClose={handleSupportClose}
              />
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ ml: "10px" }}>
                  <Person3OutlinedIcon />
                </IconButton>
              </Tooltip>
              <ProfileMenu
                anchorElUser={anchorElUser}
                handleCloseUserMenu={handleCloseUserMenu}
                userData={userData}
              />
            </Box>
          </>
        )}
      </Container>
    </AppBar>
  );
};
export default Header;
