import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import TextField from "@mui/material/TextField";
import "./EmailConfig.scss";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { addEmailConfig, saveEmailConfig } from "./service";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export type EmailConfigmodalProps = {
  handleModalOpen: (data: any) => void;
  handleModalClose: () => void;
  data: any;
  isOpen: boolean;
  reloadData: () => void;
  showNotification: (message: string, isError: boolean) => void;
};

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const EmailConfigmodal = ({
  isOpen,
  handleModalOpen,
  handleModalClose,
  data,
  reloadData,
  showNotification,
}: EmailConfigmodalProps) => {
  const [formData, setFormData] = React.useState<any>(data);

  React.useEffect(() => {
    setFormData(data);
  }, [data]);

  const handleSaveChanges = async () => {
    let response: any;
    if (data?.email_id) {
      response = await saveEmailConfig(formData);
    } else {
      response = await addEmailConfig({
        fromName: "esender",
        host: formData?.host,
        email: formData?.username,
        username: formData?.username,
        password: formData?.password,
        port: formData?.port,
        ssl: formData?.ssl,
      });
    }

    if (!response.error) {
      reloadData();
      handleModalClose();
      showNotification("Email configuration saved successfully.", false);
    } else {
      showNotification(response.error, true);
    }
  };

  return (
    <div>
      <div className="email-config-area--title">
        <span>SMTP Configurations</span>
        <Button
          variant="outlined"
          onClick={() => handleModalOpen(null)}
          className="add-config-button"
        >
          Add
        </Button>
      </div>
      <BootstrapDialog
        onClose={handleModalClose}
        aria-labelledby="email-config-add-modal"
        open={isOpen}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleModalClose}
        >
          Email Configuration
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <TextField
            id="host"
            label="Smtp Server"
            variant="standard"
            value={formData?.host}
            onChange={(event: any) =>
              setFormData({ ...formData, host: event.target.value })
            }
            className="email-config-input"
          />
          <TextField
            id="port"
            label="Port"
            variant="standard"
            value={formData?.port}
            onChange={(event: any) =>
              setFormData({ ...formData, port: event.target.value })
            }
            className="email-config-input"
          />

          <TextField
            id="usename"
            label="User Name"
            variant="standard"
            value={formData?.username}
            onChange={(event: any) =>
              setFormData({ ...formData, username: event.target.value })
            }
            className="email-config-input"
          />

          <TextField
            id="password"
            label="Password"
            variant="standard"
            value={formData?.password}
            onChange={(event: any) =>
              setFormData({ ...formData, password: event.target.value })
            }
            className="email-config-input"
          />
          <div className="email-config--field">
            <div className="email-config--field-label">Connection Type</div>
            <RadioGroup
              row
              value={formData?.ssl ? "ssl" : "tsl"}
              onChange={(event: any) =>
                setFormData({ ...formData, ssl: event.target.value === "ssl" })
              }
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel value="ssl" control={<Radio />} label="SSL" />
              <FormControlLabel value="tsl" control={<Radio />} label="TSL" />
            </RadioGroup>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSaveChanges}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default EmailConfigmodal;
