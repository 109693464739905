import "./AttributeForm.scss";
import { ComponentItem } from "../../ComponentList/ComponentListData";
import React from "react";
import AttributesFormBody from "./AttributesFormBody";
import TabComponent from "../../../shared/TabComponent";
import AttributesFormBodyNew from "./AttributesFormBodyNEW";

interface ColumnsAttributeFormBodyProps {
  selectedComponent: ComponentItem;
  onAttributeChange: (updatedComponent: ComponentItem) => void;
}

const ColumnsAttributeFormBody = ({
  selectedComponent,
  onAttributeChange,
}: ColumnsAttributeFormBodyProps) => {
  const columnLables = [];
  for (
    let step = 0;
    step < selectedComponent?.props?.properties?.noOfColumns;
    step++
  ) {
    columnLables.push(`Column ${step + 1}`);
  }

  const onColumnsAttributeChange = (updatedItem: ComponentItem) => {
    const newItems =
      selectedComponent?.props?.properties?.items.map((item: ComponentItem) =>
        item.id === updatedItem.id ? updatedItem : item
      ) || [];

    selectedComponent.props.properties.items = newItems;
    onAttributeChange(selectedComponent);
  };
  const tabItems = selectedComponent?.props?.properties?.items.map(
    (item: ComponentItem) => (
      <AttributesFormBodyNew
        selectedComponent={item}
        onAttributeChange={onColumnsAttributeChange}
      />
    )
  );
  return (
    <TabComponent
      id="column-settings"
      labels={["General", ...columnLables]}
      items={[
        <AttributesFormBodyNew
          selectedComponent={selectedComponent}
          onAttributeChange={onAttributeChange}
        />,
        ...tabItems,
      ]}
    />
  );
};

export default ColumnsAttributeFormBody;
