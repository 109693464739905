const HtmlSection = ({ properties, style }: any) => {
  const TextDivStyle = {
    paddingTop: `${style.paddingTop}px`,
    paddingRight: `${style.paddingRight}px`,
    paddingBottom: `${style.paddingBottom}px`,
    paddingLeft: `${style.paddingLeft}px`,
    marginTop: `${style.marginTop}px`,
    marginRight: `${style.marginRight}px`,
    marginBottom: `${style.marginBottom}px`,
    marginLeft: `${style.marginLeft}px`,
    backgroundColor: style.backgroundColor,
    border: `${style.borderSize}px ${style.borderType} ${style.borderColor}`,
    zIndex: style.zIndex,
  };
  return (
    <div
      style={{ ...TextDivStyle, position: "relative" }}
      dangerouslySetInnerHTML={{ __html: properties?.htmlCode }}
    />
  );
};

export default HtmlSection;
