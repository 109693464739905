import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import TextField from "@mui/material/TextField";
import { addGroup, AxiosResponse, saveGroup } from "./service";

export type GroupModalProps = {
  handleModalOpen: (data: any) => void;
  handleModalClose: () => void;
  data: any;
  isOpen: boolean;
  reloadData: () => void;
  showNotification: (message: string, isError: boolean) => void;
};

const GroupModal = ({
  isOpen,
  handleModalOpen,
  handleModalClose,
  data,
  reloadData,
  showNotification,
}: GroupModalProps) => {
  const [formData, setFormData] = React.useState<any>(data);

  React.useEffect(() => {
    console.log(data);
    setFormData(data);
  }, [data]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let response: any;
    if (data) {
      response = await saveGroup(formData);
    } else {
      response = await addGroup({
        name: formData.name,
      });
    }

    if (!response.error) {
      reloadData();
      handleModalClose();
      showNotification("Group saved successfully.", false);
    } else {
      showNotification(response.error, true);
    }
  };

  return (
    <div>
      <div className="email-config-area--title">
        <span>Groups</span>
        <Button
          variant="outlined"
          onClick={() => handleModalOpen(null)}
          className="add-config-button"
        >
          Add
        </Button>
      </div>
      <Dialog
        open={isOpen}
        onClose={handleModalClose}
        className="add-group-modal"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new group</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={formData?.name}
              onChange={(event) =>
                setFormData({ ...formData, name: event.target.value })
              }
              label="Group Name"
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default GroupModal;
