import { Paper, Skeleton, Stack } from "@mui/material";
import "./TemplateList.scss";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { AxiosResponse, getAllTemplates } from "./service";

type TemplateItemProps = {
  id: string;
  name: string;
  thumb: any;
  onlySelect?: boolean;
  getSelectedTemplate?: (templateId: string) => void;
  selectedTemplateId?: string;
};
const TemplateItem = ({
  id,
  name,
  thumb,
  onlySelect,
  getSelectedTemplate,
  selectedTemplateId,
}: TemplateItemProps) => {
  let navigate = useNavigate();

  return (
    <Paper
      className={`template-list--item ${
        selectedTemplateId === id ? "selected" : ""
      }`}
      onClick={() =>
        !onlySelect ? navigate(`/template/${id}`) : getSelectedTemplate?.(id)
      }
    >
      <div className="template-thumb">{thumb}</div>
      <div className="item-label">{name}</div>
    </Paper>
  );
};

const TemplateList = ({
  onlySelect,
  getSelectedTemplate,
  selectedTemplateId,
}: any) => {
  const [templateList, setTemplateList] = useState<any[]>([]);

  useEffect(() => {
    reloadData();
  }, []);

  const reloadData = async () => {
    const response: AxiosResponse = await getAllTemplates({
      page: 0,
      limit: 100,
    });

    if (!response.error) {
      await setTemplateList(response.data);
    } else {
    }
  };

  return (
    <div className="template-list">
      {!onlySelect && <div className="template-list--title">Templates</div>}
      <div className="template-list--area">
        {!onlySelect && (
          <TemplateItem
            id="new"
            name="New Template"
            thumb={<AddIcon className="add-icon-item" />}
          />
        )}
        {templateList.map((template: any) => (
          <TemplateItem
            id={template._id}
            name={template.name}
            key={template._id}
            thumb={
              <Stack spacing={1} className="template-thumb">
                <img src={template.preview} alt={template.name} />
              </Stack>
            }
            onlySelect={onlySelect}
            getSelectedTemplate={getSelectedTemplate}
            selectedTemplateId={selectedTemplateId}
          />
        ))}
      </div>
    </div>
  );
};

export default TemplateList;
