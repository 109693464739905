import CodeEditor from "@uiw/react-textarea-code-editor";
import "./HtmlInput.scss";

export interface HtmlInputProps {
  code?: string;

  onChange: (newValue: string) => void;
}

const HtmlInput = ({ code = "", onChange }: HtmlInputProps) => {
  return (
    <div className="html-input-field">
      <CodeEditor
        value={code}
        language="html"
        placeholder="Please enter HTML code."
        onChange={(evn) => onChange(evn.target.value)}
        padding={15}
        minHeight={300}
      />
    </div>
  );
};

export default HtmlInput;
