import React from "react";

const FormField = (props: any) => {
  return (
    <div className="attribute-form--field">
      {props.label && (
        <div className="attribute-form--field-label">{props.label}</div>
      )}
      <div
        className={`attribute-form--field-input ${props.inputClassName?.join(
          " "
        )}`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default FormField;
