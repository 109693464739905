import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import EmailBook from "./EmailBook/EmailBook";
import Header from "./Header/Header";
import Login from "./Login/Login";
import Logout from "./Login/Logout";
import Settings from "./Settings/Settings";
import { isLoggedIn } from "./shared/utils";
import SideBar from "./SideBar/SideBar";
import Template from "./Template/Template";
import TemplateList from "./Template/TemplateList";
import EmailConfig from "./EmailConfig/EmailConfig";
import User from "./User/User";
import Campaign from "./Campaign/Campaign";
import CreateCampaign from "./Campaign/CreateCampaign";
import Groups from "./Groups/Groups";
import { getUserProfile } from "./User/service";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1BB0CE",
    },
  },
  typography: {
    fontFamily: [
      "sans-serif",
      '"Helvetica Neue"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Arial",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 14,
  },
});

const PrivateRoute = ({ children }: any) => {
  const auth = isLoggedIn();
  return auth ? children : <Login />;
};

const App = () => {
  const [title, setTitle] = useState("");
  const [userData, setUserData] = useState<any>();

  useEffect(() => {
    if (isLoggedIn()) {
      getUserProfile()
        .then((res) => {
          setUserData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          {isLoggedIn() && <Header title={title} userData={userData} />}
          <section className={`App-content ${isLoggedIn() && "user-content"}`}>
            {isLoggedIn() && <SideBar />}

            <div className="main-content">
              <Routes>
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <Campaign />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/campaign/:id"
                  element={
                    <PrivateRoute>
                      <CreateCampaign />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/templates"
                  element={
                    <PrivateRoute>
                      <TemplateList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={"template/:id"}
                  element={
                    <PrivateRoute>
                      <Template setHeaderTitle={setTitle} />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/user-management"
                  element={
                    <PrivateRoute>
                      <User />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/contacts"
                  element={
                    <PrivateRoute>
                      <EmailBook />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/groups"
                  element={
                    <PrivateRoute>
                      <Groups />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <PrivateRoute>
                      <Settings />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/smtp-config"
                  element={
                    <PrivateRoute>
                      <EmailConfig />
                    </PrivateRoute>
                  }
                />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
              </Routes>
            </div>
          </section>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
};

export default App;
