import React from "react";

const SocialComponent = ({ properties, style }: any) => {
  const TextDivStyle = {
    paddingTop: `${style.paddingTop}px`,
    paddingRight: `${style.paddingRight}px`,
    paddingBottom: `${style.paddingBottom}px`,
    paddingLeft: `${style.paddingLeft}px`,
    marginTop: `${style.marginTop}px`,
    marginRight: `${style.marginRight}px`,
    marginBottom: `${style.marginBottom}px`,
    marginLeft: `${style.marginLeft}px`,
    textAlign: style.textAlign,
    display: "flex",
    justifyContent: style.textAlign,
    gap: `${style.iconSpacing}px`,
    flexWrap: "wrap" as any,
    backgroundColor: style.backgroundColor,
    border: `${style.borderSize}px ${style.borderType} ${style.borderColor}`,
    zIndex: style.zIndex,
  };

  const StyledLinkStyle = {
    display: "inline-block",
    width: "32px",
    height: "32px",
  };

  const StyledNoItem = {
    textAlign: "center" as "center",
    width: "100%",
  };

  const defaultProps = {
    target: "_blank",
  };
  return (
    <div style={{ ...TextDivStyle, position: "relative" }}>
      {properties?.socialItems?.length > 0 ? (
        properties?.socialItems.map((item: any) => (
          <a
            style={{ ...StyledLinkStyle }}
            href={item.url}
            {...defaultProps}
            rel="noreferrer"
          >
            <img src={item.iconUrl} alt={item.name} width="32" height="32" />
          </a>
        ))
      ) : (
        <div style={{ ...StyledNoItem }}>Add Social Media </div>
      )}
    </div>
  );
};

export default React.memo(SocialComponent);
