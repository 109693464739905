import { IconButton, Tooltip } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import "./AttributeForm.scss";
import { ComponentItem } from "../../ComponentList/ComponentListData";
import ConfirmationDialog from "../../../shared/ConfirmationDialog";
import { useState } from "react";

interface AttributeFormHeaderProps {
  selectedComponent: ComponentItem;
  closeForm: () => void;
  deleteComponent: (selectedComponent: ComponentItem) => void;
}
const AttributesFormHeader = ({
  selectedComponent,
  closeForm,
  deleteComponent,
}: AttributeFormHeaderProps) => {
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const { name } = selectedComponent;

  return (
    <div className="attribute-form--header">
      <div className="attribute-form--header-title">{name}</div>
      <Tooltip title="Delete">
        <div className="attribute-form--header-button delete">
          <IconButton
            aria-label="back"
            onClick={() => setConfirmationDialogOpen(true)}
            disableRipple={true}
          >
            <DeleteForeverIcon />
          </IconButton>
          <ConfirmationDialog
            desription="Are you sure you want to delete this component?"
            isOpen={isConfirmationDialogOpen}
            handleClose={() => {
              setConfirmationDialogOpen(false);
            }}
            handleOnAgree={() => {
              setConfirmationDialogOpen(false);
              deleteComponent(selectedComponent);
            }}
          />
        </div>
      </Tooltip>

      <div className="attribute-form--header-button close">
        <IconButton aria-label="back" onClick={closeForm} disableRipple={true}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default AttributesFormHeader;
