import { TextField } from "@mui/material";
import { CampaignDataItem } from "./CreateCampaign";

type SubjectSettingsProps = {
  campaignData: CampaignDataItem;
  setCampaignData: (value: CampaignDataItem) => void;
};

const SubjectSettings = ({
  campaignData,
  setCampaignData,
}: SubjectSettingsProps) => {
  return (
    <div className="campaign-settings-area">
      <div className="campaign-settings-area--title">Subject Settings</div>
      <div className="campaign-settings-area--content">
        <TextField
          name="subject"
          disabled={campaignData.id !== "new"}
          value={campaignData.subject.name}
          onChange={(event) =>
            setCampaignData({
              ...campaignData,
              subject: { ...campaignData.subject, name: event.target.value },
            })
          }
          label="Subject Line"
          variant="standard"
          className="campaign-settings-area--input"
        />
        <TextField
          name="preview-text"
          disabled={campaignData.id !== "new"}
          value={campaignData.subject.preview}
          onChange={(event) =>
            setCampaignData({
              ...campaignData,
              subject: { ...campaignData.subject, preview: event.target.value },
            })
          }
          label="Preview Text"
          variant="standard"
          className="campaign-settings-area--input"
        />
      </div>
    </div>
  );
};

export default SubjectSettings;
