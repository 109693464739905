import { axiosGet, axiosPost } from "../shared/axiosService";

export type AxiosResponse = {
  data: any[];
  error: any;
  newId?: string;
  s3path?: string;
  downloadUrl?: string;
};

export const getAllTemplates = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("template/all", body);
    if (res.data.status) {
      response.data = res.data.templates;
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};

export const addTemplate = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("template/add", body, {
      "content-type": "multipart/form-data",
    });
    if (res.data.status) {
      response.data = res.data.templates;
      response.newId = res.data.template_id;
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};

export const saveTemplate = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("template/save", body, {
      "content-type": "multipart/form-data",
    });
    if (res.data.status) {
      response.data = res.data.templates;
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};

export const deleteTemplateByID = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("template/delete", body);
    if (res.data.status) {
      response.data = res.data.contacts;
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};

export const getAllImages = async () => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosGet("user/media");
    if (res.data.status) {
      response.data = res.data.media;
      response.s3path = res.data.s3Path;
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};

export const getAllIcon = async () => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosGet("app/icons");
    if (res.data.status) {
      response.data = res.data.data;
      response.s3path = res.data.s3Path;
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};

export const uploadImage = async (image: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost(
      "user/upload-media",
      { image: image },
      {
        "content-type": "multipart/form-data",
      }
    );
    if (res.data.status) {
      response.data = res.data.data;
      response.downloadUrl = res.data.downloadUrl;
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};
