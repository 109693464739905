import html2canvas from "html2canvas";

const exportAsImage = async (element: any, imageFileName: any) => {
  const html = document.getElementsByTagName("html")[0];
  const body = document.getElementsByTagName("body")[0];
  let htmlWidth = html.clientWidth;
  let bodyWidth = body.clientWidth;

  const newWidth = element.scrollWidth - element.clientWidth;

  if (newWidth > element.clientWidth) {
    htmlWidth += newWidth;
    bodyWidth += newWidth;
  }

  html.style.width = htmlWidth + "px";
  body.style.width = bodyWidth + "px";

  const canvas = await html2canvas(element);
  const image = canvas.toDataURL();
  //  downloadImage(image, imageFileName);
  return dataURItoBlob(image);
};

const dataURItoBlob = (dataURI: any) => {
  var blobBin = atob(dataURI.split(",")[1]);
  var array = [];
  for (var i = 0; i < blobBin.length; i++) {
    array.push(blobBin.charCodeAt(i));
  }
  var file = new Blob([new Uint8Array(array)], { type: "image/png" });

  return file;
};

const downloadImage = (blob: string, fileName: string) => {
  const link = document.createElement("a");
  link.download = "filename.png";
  link.href = blob;
  link.click();
};

export default exportAsImage;
