import React, { useEffect, useRef, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import "./Template.scss";
import { v4 as uuid } from "uuid";
import { COMPONENT_LIST_AREA } from "./ComponentList/ComponentList";
import HtmlBuilder, { HTML_BUILDER_AREA } from "./Builder/HtmlBuilder";
import ReactDOMServer from "react-dom/server";
import {
  componentList,
  ComponentItem,
  globalStyleComponentData,
} from "./ComponentList/ComponentListData";
import HtmlSettings, { VIEWS } from "./Builder/HtmlSettings/HtmlSettings";
import { NAVIGATION_AREA } from "./Builder/Navigation/Navigation";
import TemplateSettingsModal from "./TemplateSettingsModal";
import {
  addTemplate,
  AxiosResponse,
  deleteTemplateByID,
  getAllTemplates,
  saveTemplate,
} from "./service";
import { useNavigate, useParams } from "react-router-dom";
import { State } from "../EmailBook/EmailBook";
import { Alert, Snackbar } from "@mui/material";
import exportAsImage from "../shared/exportImage";

export type TemplateProps = {
  setHeaderTitle: (title: string) => void;
};

const Template = ({ setHeaderTitle }: TemplateProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [templateMetadata, setTemplateMetadata] = useState<any>();
  const [htmlComponents, updateHtmlComponents] = useState<ComponentItem[]>([]);
  const [openAttributesForm, setOpenAttributesForm] = useState<boolean>(false);
  const [selectedComponent, setSelectedComponent] = useState<ComponentItem>();
  const [globalStyleComponent, setGlobalStyleComponent] =
    useState<ComponentItem>(globalStyleComponentData);
  const [previewMode, setPreviewMode] = useState(VIEWS.DESKTOP);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    reloadData();
  }, []);

  useEffect(() => {
    setHeaderTitle(templateMetadata?.name || "New Template");

    return () => {
      setHeaderTitle("");
    };
  }, [setHeaderTitle, templateMetadata?.name]);

  const reloadData = async () => {
    const response: AxiosResponse = await getAllTemplates({
      page: 0,
      limit: 100,
    });

    if (!response.error) {
      const template = response.data.find(
        (template: any) => template._id === id
      );
      if (template) {
        setTemplateMetadata({
          name: template.name,
          tags: template.tags,
        });
        const tempaletJson = JSON.parse(template.json);
        setGlobalStyleComponent({
          ...tempaletJson.globalStyleComponent,
        });
        updateHtmlComponents([...tempaletJson.items]);
        setHeaderTitle(template.name);
      } else {
        const name = "";
        setTemplateMetadata({
          name,
          tags: [],
        });
        setHeaderTitle(name);
      }
    }
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [notificationState, setNotificationState] = useState<State>({
    open: false,
    message: "",
    isError: false,
    vertical: "top",
    horizontal: "center",
    severity: "success",
  });

  const showNotification = (message: string, isError: boolean) => {
    setNotificationState({
      ...notificationState,
      open: true,
      message,
      isError,
      severity: isError ? "error" : "success",
    });
  };

  const closeNotification = () => {
    setNotificationState({
      ...notificationState,
      open: false,
      message: "",
      isError: false,
    });
  };

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const { source, destination } = result;

    let draggedComponent;
    let newHtmlComponents = [...htmlComponents];

    if (
      destination.droppableId === HTML_BUILDER_AREA &&
      source.droppableId === COMPONENT_LIST_AREA
    ) {
      draggedComponent = {
        // ...JSON.parse(JSON.stringify(componentList[source.index])),
        ...componentList[source.index],
        id: uuid(),
        componentRender: componentList[source.index].componentRender,
      };
    } else if (
      (destination.droppableId === HTML_BUILDER_AREA &&
        source.droppableId === HTML_BUILDER_AREA) ||
      (destination.droppableId === NAVIGATION_AREA &&
        source.droppableId === NAVIGATION_AREA)
    ) {
      draggedComponent = {
        // ...JSON.parse(JSON.stringify(htmlComponents[source.index])),
        ...htmlComponents[source.index],
        componentRender: htmlComponents[source.index].componentRender,
      };
      newHtmlComponents.splice(source.index, 1);
    }

    if (draggedComponent) {
      newHtmlComponents.splice(destination.index, 0, draggedComponent);
      updateHtmlComponents(newHtmlComponents);
      openAttributeForm(draggedComponent);
    }
  }

  const openAttributeForm = (componentSelected: ComponentItem) => {
    setSelectedComponent(componentSelected);
    setOpenAttributesForm(true);
  };

  const closeAttributeForm = () => {
    setSelectedComponent(undefined);
    setOpenAttributesForm(false);
  };

  const deleteComponent = (selectedComponent: ComponentItem) => {
    const newHtmlComponents = htmlComponents.filter(
      (component) => component.id !== selectedComponent.id
    );
    updateHtmlComponents(newHtmlComponents);
    setSelectedComponent(undefined);
    setOpenAttributesForm(false);
  };

  const onAttributeChange = (updatedComponent: ComponentItem) => {
    const newHtmlComponents = htmlComponents.map((component) =>
      component.id !== updatedComponent.id ? component : updatedComponent
    );
    updateHtmlComponents(newHtmlComponents);
    setSelectedComponent(updatedComponent);
  };

  const generateHtml = () => {
    const reactComponent = (
      <div className="template-area--component-builder">
        {htmlComponents.map((component, index) => {
          const { id, componentRender: ComponentRender, props } = component;
          const componentProps = { ...props, id };
          return (
            <div className={`template-area--component-item`} key={id}>
              <ComponentRender {...componentProps} />
            </div>
          );
        })}
      </div>
    );
    console.log(ReactDOMServer.renderToString(reactComponent));
  };

  const exportRef = useRef<HTMLDivElement>();
  const generateImage = async () => {
    return await exportAsImage(exportRef.current, "test");
  };

  const onSaveTemplate = async () => {
    const imageData = await generateImage();

    const bodyFormData = new FormData();
    if (templateMetadata?.name) {
      const template = {
        globalStyleComponent,
        items: htmlComponents,
      };

      let response: AxiosResponse;

      const imageFile = new File([imageData], templateMetadata.name);
      bodyFormData.append("name", templateMetadata.name);
      bodyFormData.append("tags", templateMetadata.tags);
      bodyFormData.append("json", JSON.stringify(template));
      bodyFormData.append("preivew", imageFile);

      if (id === "new") {
        response = await addTemplate(bodyFormData);
      } else {
        bodyFormData.append("template_id", id || "");
        response = await saveTemplate(bodyFormData);
      }

      if (!response.error) {
        showNotification("Template saved successfully.", false);
        if (id === "new") {
          navigate(`/template/${response.newId || "new"}`);
        }
      } else {
        showNotification(response.error, true);
      }
    } else {
      showNotification("Please add template name.", true);
      handleOpen();
    }
  };

  const deleteTemplate = async () => {
    const response = await deleteTemplateByID({
      template_id: id,
    });

    if (!response.error) {
      showNotification("Template deleted successfully.", false);
      navigate("/templates");
    } else {
      showNotification(response.error, true);
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="template-area">
          <div className="template-area--section template-area--component-list">
            <HtmlSettings
              componentList={componentList}
              htmlComponents={htmlComponents}
              selectedComponent={selectedComponent}
              globalStyleComponent={globalStyleComponent}
              isOpen={openAttributesForm}
              closeForm={closeAttributeForm}
              deleteComponent={deleteComponent}
              onAttributeChange={onAttributeChange}
              onGlobalStyleChange={(updatedComponent) =>
                setGlobalStyleComponent(updatedComponent)
              }
              generateHtml={generateHtml}
              saveTemplate={onSaveTemplate}
              previewMode={previewMode}
              onPreviewChange={(value: VIEWS) => setPreviewMode(value)}
              handleOpen={handleOpen}
              deleteTemplate={deleteTemplate}
              isNewTemplate={id === "new"}
            />
          </div>
          <div
            className="template-area--section  template-area--html-builder"
            onClick={() => {
              closeAttributeForm();
            }}
          >
            <HtmlBuilder
              selectedCompoonentId={selectedComponent?.id}
              htmlComponents={htmlComponents}
              openAttributeForm={openAttributeForm}
              globalStyleComponent={globalStyleComponent}
              previewMode={previewMode}
              exportRef={exportRef}
            />
          </div>
        </div>
      </DragDropContext>
      <TemplateSettingsModal
        isOpen={isOpen}
        handleClose={handleClose}
        name={templateMetadata?.name}
        tags={templateMetadata?.tags}
        setTemplateMetadata={setTemplateMetadata}
      />
      <Snackbar
        anchorOrigin={{
          vertical: notificationState.vertical,
          horizontal: notificationState.horizontal,
        }}
        open={notificationState.open}
        onClose={closeNotification}
        autoHideDuration={6000}
      >
        <Alert
          onClose={closeNotification}
          severity={notificationState.severity}
          sx={{ width: "100%" }}
        >
          {notificationState.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Template;
