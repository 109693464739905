export const isLoggedIn = () => {
  return localStorage.hasOwnProperty("token");
};

export const validatePhoneNumber = (phoneNumber: string): boolean => {
  const regex = /^\d{10}$/;

  if (regex.test(phoneNumber)) {
    return true;
  } else {
    return false;
  }
};
