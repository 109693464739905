import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../shared/ConfirmationDialog";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";

export type GroupListProps = {
  groupListData: any[];
  handleModalOpen: (data: any) => void;
  deleteGroup: (id: any) => void;
  loading?: boolean;
};

const GroupList = ({
  groupListData,
  handleModalOpen,
  deleteGroup,
  loading,
}: GroupListProps) => {
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell component="th">Group Name</TableCell>

            <TableCell component="th" align="right">
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell
                scope="row"
                colSpan={5}
                align="center"
                className="no-data"
              >
                <CircularProgress color="inherit" />
              </TableCell>
            </TableRow>
          ) : (
            groupListData.length === 0 && (
              <TableRow>
                <TableCell
                  scope="row"
                  colSpan={5}
                  align="center"
                  className="no-data"
                >
                  <img
                    src="/images/no-data.png"
                    alt="No data Present"
                    className="no-data-image"
                  />
                </TableCell>
              </TableRow>
            )
          )}
          {groupListData.map((row) => {
            const { _id, name } = row;
            return (
              <TableRow
                key={_id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row">{name}</TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="edit"
                    onClick={() => handleModalOpen(row)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => setConfirmationDialogOpen(true)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <ConfirmationDialog
                    desription="Are you sure you want to delete this group"
                    isOpen={isConfirmationDialogOpen}
                    handleClose={() => setConfirmationDialogOpen(false)}
                    handleOnAgree={() => {
                      deleteGroup(row._id);
                      setConfirmationDialogOpen(false);
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GroupList;
