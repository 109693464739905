import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import TextField from "@mui/material/TextField";
import "./EmailBook.scss";
import { Typography } from "@mui/material";
import { validateContacts } from "./utils";
import { saveContact } from "./service";
import GroupSelect from "../Groups/GroupSelect";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export type EmailBookEditModalProps = {
  data: any;
  handleModalClose: () => void;
  isOpen: boolean;
  reloadData: () => void;
  groupList: any[];
  reloadGroupData: () => void;
  showNotification: (message: string, isError: boolean) => void;
};

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const EmailBookEditModal = ({
  isOpen,
  data,
  groupList,
  handleModalClose,
  reloadData,
  showNotification,
  reloadGroupData,
}: EmailBookEditModalProps) => {
  const [formData, setFormData] = useState<any>(data);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const handleSaveChanges = async () => {
    const isvalidData = validateContacts([formData]);
    console.log(formData, isvalidData);
    if (isvalidData) {
      let body: any = {
        contact_id: formData.id,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        tags: "",
        groups: formData.groups?.map((group: any) => group._id).join(",") || "",
      };
      if (formData.email !== data.email) {
        body = { ...body, email: formData.email };
      }
      const response = await saveContact(body);

      if (!response.error) {
        reloadData();
        handleModalClose();
        showNotification("Contacts saved successfully.", false);
      } else {
        showNotification(response.error, true);
      }
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleModalClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleModalClose}
        >
          Contact Details
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <TextField
            id="standard-basic"
            label="Firstname"
            variant="standard"
            value={formData?.firstName}
            onChange={(event: any) =>
              setFormData({ ...formData, firstName: event.target.value })
            }
            className="contact-edit-input"
          />

          <TextField
            id="standard-basic"
            label="Lastname"
            variant="standard"
            value={formData?.lastName}
            onChange={(event: any) =>
              setFormData({ ...formData, lastName: event.target.value })
            }
            className="contact-edit-input"
          />

          <TextField
            id="standard-basic"
            label="Email"
            variant="standard"
            value={formData?.email}
            onChange={(event: any) =>
              setFormData({ ...formData, email: event.target.value })
            }
            className="contact-edit-input"
          />
          <TextField
            id="standard-basic"
            label="Phone No."
            variant="standard"
            value={formData?.phone}
            onChange={(event: any) =>
              setFormData({ ...formData, phone: event.target.value })
            }
            className="contact-edit-input"
          />
          <div className="email-add-form--input">
            <GroupSelect
              groupList={groupList}
              values={formData?.groups}
              reloadGroupData={reloadGroupData}
              onGroupChange={(value: any) =>
                setFormData({ ...formData, groups: [value] })
              }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSaveChanges}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default EmailBookEditModal;
