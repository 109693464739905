import React from "react";
import "./FontFamilySelect.scss";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface FontFamilySelectProps {
  value: string;
  onChange: (value: string) => void;
}

const fontFamilies = [
  "Arial",
  "Verdana",
  "Tahoma",
  "Trebuchet MS",
  "Times New Roman",
  "Georgia",
  "Garamond",
  "Courier New",
  "Brush Script MT",
];
const FontFamilySelect = ({ value, onChange }: FontFamilySelectProps) => {
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={value}
        onChange={(event: SelectChangeEvent) =>
          onChange(event.target.value || "arial")
        }
        label="Age"
      >
        {fontFamilies.map((fontValue) => (
          <MenuItem value={fontValue} key={fontValue}>
            {fontValue}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FontFamilySelect;
