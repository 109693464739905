import React from "react";
import { MuiColorInput } from "mui-color-input";
import "./ColorPicker.scss";

interface ColorPickerProps {
  value: string;
  onChange: (value: string) => void;
}

const ColorPicker = ({ value, onChange }: ColorPickerProps) => {
  return (
    <MuiColorInput
      className="color-input"
      format="hex"
      isAlphaHidden={true}
      value={value}
      onChange={(newvalue: string) =>
        onChange(newvalue === "" ? "#000000" : newvalue)
      }
      variant="standard"
      style={{ width: "100px", borderBottom: "1px solid #cfd4d9" }}
      InputProps={{ disableUnderline: true }}
    />
  );
};

export default ColorPicker;
