const DividerComponent = ({ style }: any) => {
  const WrapperDivStyle = {
    paddingTop: `${style.paddingTop}px`,
    paddingRight: `${style.paddingRight}px`,
    paddingBottom: `${style.paddingBottom}px`,
    paddingLeft: `${style.paddingLeft}px`,
    marginTop: `${style.marginTop}px`,
    marginRight: `${style.marginRight}px`,
    marginBottom: `${style.marginBottom}px`,
    marginLeft: `${style.marginLeft}px`,
    justifyContent: `${style.textAlign}`,
    display: "flex",
    height: `${style.size}px`,
    backgroundColor: style.backgroundColor,
    border: `${style.borderSize}px ${style.borderType} ${style.borderColor}`,
    zIndex: style.zIndex,
  };

  const HRDivStyle = {
    borderTop: `${style.size}px ${style.dividerStyle || "solid"} ${
      style.color
    }`,
    width: `${style.width}%`,
    display: "inline-block",
  };

  return (
    <div style={{ ...WrapperDivStyle, position: "relative" }}>
      <div style={{ ...HRDivStyle }} />
    </div>
  );
};

export default DividerComponent;
