import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import FromSettings from "./FromSettings";
import ToSettings from "./ToSettings";
import SubjectSettings from "./SubjectSettings";
import DesignSettings from "./DesignSettings";
import CampaignSettings from "./CampaignSettings";
import { createCampaign, getAllContacts, getCampaignByID } from "./service";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { State } from "./Campaign";
import { getAllGroups } from "../Groups/service";

const steps = ["From", "To", "Subject", "Design", " Settings"];
export type CampaignDataItem = {
  id: string;
  name: string;
  template_id: string;
  from: {
    email: string;
    name: string;
    reply_to_email: string;
    reply_to_name: string;
  };
  accounts: string[];
  subject: {
    name: string;
    preview: string;
  };
  recipients: any[];
};

const CreateCampaign = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [campaignData, setCampaignData] = React.useState<CampaignDataItem>();
  const [groupListData, setGroupListData] = React.useState<any[]>([]);
  const [contactData, setContactData] = React.useState<any[]>([]);
  const { id: campaignId } = useParams();
  const navigate = useNavigate();

  const [notificationState, setNotificationState] = React.useState<State>({
    open: false,
    message: "",
    isError: false,
    vertical: "top",
    horizontal: "center",
    severity: "success",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showNotification = (message: string, isError: boolean) => {
    setNotificationState({
      ...notificationState,
      open: true,
      message,
      isError,
      severity: isError ? "error" : "success",
    });
  };

  const closeNotification = () => {
    setNotificationState({
      ...notificationState,
      open: false,
      message: "",
      isError: false,
    });
  };

  React.useEffect(() => {
    const getCampaign = async (id: string) => {
      const response = await getCampaignByID(id);
      if (response.error) {
        showNotification(response.error.status, true);
      } else {
        setCampaignData(response.data[0]);
      }
    };

    if (campaignId && campaignId !== "new") {
      getCampaign(campaignId);
    } else {
      setCampaignData({
        id: "new",
        name: "New Campaign",
        template_id: "63fc91ed60fbd62fe8fbad9d",
        from: {
          email: "",
          name: "",
          reply_to_email: "",
          reply_to_name: "",
        },
        accounts: [],
        subject: {
          name: "",
          preview: "",
        },
        recipients: [],
      });
    }
  }, [campaignId]);

  React.useEffect(() => {
    reloadData();
  }, []);

  const reloadData = async () => {
    const [contactRes, groupRes] = await Promise.all([
      getAllContacts({
        page: 0,
        limit: 100,
      }),
      getAllGroups({
        page: 0,
        limit: 100,
      }),
    ]);

    setContactData(contactRes?.data ?? []);

    setGroupListData(groupRes?.data ?? []);
  };

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      if (campaignData?.id === "new") {
        const response = await createCampaign(campaignData);
        navigate(`/campaign/${response.data[0]}`);
      }
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  if (!campaignData) {
    return <CircularProgress color="inherit" />;
  }

  return (
    <div className="campaign-area">
      <div className="campaign-area--list">
        <div className="campaign-area--title">
          <span>{campaignData.name}</span>
        </div>
      </div>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div className="campaign-area--step-content">
          {activeStep === 0 && (
            <FromSettings
              campaignData={campaignData}
              setCampaignData={setCampaignData}
            />
          )}
          {activeStep === 1 && (
            <ToSettings
              campaignData={campaignData}
              contactData={contactData}
              groupData={groupListData}
              setCampaignData={setCampaignData}
            />
          )}
          {activeStep === 2 && (
            <SubjectSettings
              campaignData={campaignData}
              setCampaignData={setCampaignData}
            />
          )}
          {activeStep === 3 && (
            <DesignSettings
              campaignData={campaignData}
              setCampaignData={setCampaignData}
            />
          )}
          {activeStep === 4 && (
            <CampaignSettings
              campaignData={campaignData}
              setCampaignData={setCampaignData}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: 2,
            justifyContent: "end",
          }}
        >
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box />
          <Button onClick={handleNext}>
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </div>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: notificationState.vertical,
          horizontal: notificationState.horizontal,
        }}
        open={notificationState.open}
        onClose={closeNotification}
        autoHideDuration={6000}
      >
        <Alert
          onClose={closeNotification}
          severity={notificationState.severity}
          sx={{ width: "100%" }}
        >
          {notificationState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CreateCampaign;
