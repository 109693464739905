import React, { useState } from "react";
import Box from "@mui/material/Box";
import ComponentList from "../../ComponentList/ComponentList";
import { ComponentItem } from "../../ComponentList/ComponentListData";
import "./HtmlSettings.scss";
import AttributesForm from "./../AttributesForm/AttributesForm";
import AttributesFormBody from "./../AttributesForm/AttributesFormBody";
import { Button, IconButton, Tooltip } from "@mui/material";
import TabComponent from "../../../shared/TabComponent";
import NavigationComponent from "./../Navigation/Navigation";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MonitorIcon from "@mui/icons-material/Monitor";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../../../shared/ConfirmationDialog";
import AttributesFormBodyNew from "../AttributesForm/AttributesFormBodyNEW";
import { useNavigate } from "react-router-dom";

type HtmlSettingsProps = {
  componentList: ComponentItem[];
  htmlComponents: ComponentItem[];
  selectedComponent?: ComponentItem;
  globalStyleComponent: ComponentItem;
  isOpen: boolean;
  closeForm: () => void;
  deleteComponent: (selectedComponent: ComponentItem) => void;
  onAttributeChange: (updatedComponent: ComponentItem) => void;
  onGlobalStyleChange: (updatedCompoennt: ComponentItem) => void;
  generateHtml: () => void;
  saveTemplate: () => void;
  previewMode: VIEWS;
  onPreviewChange: (value: VIEWS) => void;
  handleOpen: () => void;
  deleteTemplate: () => void;
  isNewTemplate: boolean;
};

export enum VIEWS {
  DESKTOP = "desktop",
  MOBILE = "mobile",
}

const HtmlSettings = ({
  componentList,
  htmlComponents,
  selectedComponent,
  globalStyleComponent,
  isOpen,
  closeForm,
  deleteComponent,
  onAttributeChange,
  onGlobalStyleChange,
  generateHtml,
  saveTemplate,
  previewMode,
  onPreviewChange,
  handleOpen,
  deleteTemplate,
  isNewTemplate,
}: HtmlSettingsProps) => {
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <TabComponent
        id="html-settings"
        labels={["Components", "Design", "Navigation"]}
        items={[
          <>
            <ComponentList components={componentList} />
            {selectedComponent && (
              <AttributesForm
                selectedComponent={selectedComponent}
                isOpen={isOpen}
                closeForm={closeForm}
                deleteComponent={deleteComponent}
                onAttributeChange={onAttributeChange}
              />
            )}
          </>,
          <AttributesFormBodyNew
            key="attribute-form"
            selectedComponent={globalStyleComponent}
            onAttributeChange={onGlobalStyleChange}
          />,
          <NavigationComponent
            key="navigation=component"
            htmlComponents={htmlComponents}
          />,
        ]}
      />
      <Box
        sx={{
          borderTop: 1,
          borderColor: "divider",
        }}
        className="settings-actions"
      >
        <div>
          {!isNewTemplate && (
            <Tooltip title="Delete Template">
              <IconButton
                aria-label="delete"
                onClick={(event: any) => {
                  setConfirmationDialogOpen(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Template Settings">
            <IconButton className="export-button" onClick={handleOpen}>
              <SettingsOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Button
            variant="contained"
            className="action-button"
            onClick={() => navigate("/templates")}
          >
            Close
          </Button>
          &nbsp;
          <Button
            variant="contained"
            className="action-button"
            onClick={saveTemplate}
          >
            Save
          </Button>
          <ConfirmationDialog
            desription="Are you sure you want to delete this template?"
            isOpen={isConfirmationDialogOpen}
            handleClose={() => {
              setConfirmationDialogOpen(false);
            }}
            handleOnAgree={() => {
              setConfirmationDialogOpen(false);
              deleteTemplate();
            }}
          />
        </div>
        <div className="view-buttons">
          <Tooltip title="Desktop">
            <IconButton
              className={`icon-button ${
                previewMode === VIEWS.DESKTOP && "active"
              }`}
              aria-label="back"
              onClick={() => onPreviewChange(VIEWS.DESKTOP)}
              disableRipple={true}
            >
              <MonitorIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Mobile">
            <IconButton
              className={`icon-button ${
                previewMode === VIEWS.MOBILE && "active"
              }`}
              aria-label="back"
              onClick={() => onPreviewChange(VIEWS.MOBILE)}
              disableRipple={true}
            >
              <PhoneIphoneIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Box>
    </>
  );
};

export default HtmlSettings;
