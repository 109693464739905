import {
  Alert,
  Button,
  Snackbar,
  SnackbarOrigin,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AxiosResponse, getUserProfile, saveUserProfile } from "./service";
import "./User.scss";

export interface State extends SnackbarOrigin {
  open: boolean;
  message: string;
  isError: boolean;
  severity?: any;
}

const User = () => {
  const [profileData, setProfileData] = useState<any>();
  const [notificationState, setNotificationState] = useState<State>({
    open: false,
    message: "",
    isError: false,
    vertical: "top",
    horizontal: "center",
    severity: "success",
  });

  const showNotification = (message: string, isError: boolean) => {
    setNotificationState({
      ...notificationState,
      open: true,
      message,
      isError,
      severity: isError ? "error" : "success",
    });
  };

  const closeNotification = () => {
    setNotificationState({
      ...notificationState,
      open: false,
      message: "",
      isError: false,
    });
  };

  useEffect(() => {
    getUserProfile()
      .then((res) => {
        setProfileData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const saveprofile = async () => {
    const response: AxiosResponse = await saveUserProfile(profileData);
    if (!response.error) {
      showNotification("Profile updated Successfully", false);
    } else {
      showNotification(response.error.status, true);
    }
  };

  return (
    <div className="user-area">
      <div className="user-area--title">My Profile</div>

      <div className="user-area--form">
        <div className="my-information--data">
          <TextField
            id="name"
            label="Name"
            variant="standard"
            value={profileData?.name}
            className="profile-input"
            InputLabelProps={{ shrink: true }}
            onChange={(event: any) => {
              setProfileData({ ...profileData, name: event.target.value });
            }}
          />

          <TextField
            id="email"
            label="Email-id"
            variant="standard"
            className="profile-input"
            value={profileData?.email}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              setProfileData({ ...profileData, email: event.target.value });
            }}
          />
          <TextField
            id="phone"
            label="Phone No"
            variant="standard"
            className="profile-input"
            InputLabelProps={{ shrink: true }}
            value={profileData?.phone}
          />
          <div className="submit-button">
            <Button variant="outlined" onClick={saveprofile}>
              Submit
            </Button>
          </div>
        </div>

        <div className="my-information--redeme">
          <div className="user-area--title-subscribe">Subscribe to Plan</div>
          <div className="my-information--redeme-input">
            <TextField
              id="redeme"
              label="Redeme Code"
              variant="standard"
              className="profile-input"
            />
            <div className="submit-button">
              <Button variant="outlined">Redeme</Button>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: notificationState.vertical,
          horizontal: notificationState.horizontal,
        }}
        open={notificationState.open}
        onClose={closeNotification}
        autoHideDuration={6000}
      >
        <Alert
          onClose={closeNotification}
          severity={notificationState.severity}
          sx={{ width: "100%" }}
        >
          {notificationState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default User;
