import { axiosPost } from "../shared/axiosService";

export type AxiosResponse = {
  data: any[];
  addResponse?: any;
  error: any;
};

export const getAllContacts = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("contact/all", body);
    if (res.data.status) {
      response.data = res.data.contacts;
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};

export const addContact = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("contact/add", body);

    if (res.data.status) {
      response.data = res.data.contacts;
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};

export const addBulkContact = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("contact/import", body);

    if (res.data.status) {
      response.data = res.data.contacts;
      response.addResponse = res.data.result;
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};

export const saveContact = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("contact/save", body);

    if (res.data.status) {
      response.data = res.data.contacts;
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};

export const deleteContactByID = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("contact/delete", body);
    if (res.data.status) {
      response.data = res.data.contacts;
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};
