import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import { MuiChipsInput } from "mui-chips-input";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
type TemplateSettingsModalProps = {
  name: string;
  tags: string[];
  isOpen: boolean;
  handleClose: () => void;
  setTemplateMetadata: (data: any) => void;
};
const TemplateSettingsModal = ({
  isOpen,
  handleClose,
  name,
  tags,
  setTemplateMetadata,
}: TemplateSettingsModalProps) => {
  const [templateName, setTemplateName] = useState<string>();
  const [templateTags, setTemplateTags] = useState<string[]>([]);

  useEffect(() => {
    setTemplateName(name);
    setTemplateTags(tags);
  }, [name, tags]);

  const onClose = () => {
    handleClose();
  };

  const onSaveChanges = async () => {
    setTemplateMetadata({ name: templateName, tags: templateTags });
    handleClose();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="template-settings-modal"
        open={isOpen}
        className="template-settings-modal"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
          Template Settings
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <TextField
            id="controlled-outline-name"
            label="Name"
            variant="standard"
            value={templateName}
            onChange={(event: any) => setTemplateName(event.target.value)}
            className="email-config-input"
          />
          <MuiChipsInput
            value={templateTags}
            onChange={(newChips) => {
              console.log(newChips);
              setTemplateTags(newChips);
            }}
            variant="standard"
            sx={{ width: "100%" }}
            className="email-config-input chips-input"
            label="Tags"
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onSaveChanges}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default TemplateSettingsModal;
