import { Button, TextField } from "@mui/material";
import "./NumberInput.scss";
import AddIcon from "@mui/icons-material/Add";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { useEffect, useState } from "react";

export interface NumberInputProps {
  label?: string;
  minValue?: number;
  maxValue?: number;
  value: number;
  onChange: (newValue: number) => void;
}

const NumberInput = ({
  label,
  value = 0,
  onChange,
  minValue,
  maxValue,
}: NumberInputProps) => {
  const [inputValue, setInputValue] = useState(`${value}`);
  console.log(label, minValue);
  useEffect(() => {
    setInputValue(`${value}`);
  }, [value]);

  const onInputChange = (value: string) => {
    let newValue = 0;
    console.log("value : ", value);
    if (value) {
      newValue = parseInt(value);
    }

    if (minValue && newValue < minValue) {
      newValue = minValue;
    }

    if (maxValue && newValue > maxValue) {
      newValue = maxValue;
    }

    onChange(newValue);
  };
  return (
    <div className="number-field">
      {label && <label className="number-field--label">{label}</label>}
      <div className="number-field--content">
        <div className="number-field--button">
          <Button
            onClick={() => onChange(value - 1)}
            disabled={
              minValue !== undefined && value === minValue ? true : false
            }
          >
            <RemoveRoundedIcon />
          </Button>
        </div>
        <TextField
          className="number-field--input"
          value={inputValue}
          variant="standard"
          style={{ width: "50px", textAlign: "center" }}
          InputProps={{ disableUnderline: true }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setInputValue(event.target.value)
          }
          onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
            onInputChange(event.target.value)
          }
        />
        <div className="number-field--button">
          <Button
            onClick={() => onChange(value + 1)}
            disabled={
              maxValue !== undefined && value >= maxValue ? true : false
            }
          >
            <AddIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NumberInput;
