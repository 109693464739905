import React from "react";
import "./BorderTypeSelect.scss";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface BorderTypeSelectProps {
  value: string;
  onChange: (value: string) => void;
}

const borderTypes = ["solid", "dashed", "dotted", "none"];
const BorderTypeSelect = ({ value, onChange }: BorderTypeSelectProps) => {
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={value}
        onChange={(event: SelectChangeEvent) =>
          onChange(event.target.value || "solid")
        }
        label="Border Type"
      >
        {borderTypes.map((value) => (
          <MenuItem
            value={value}
            key={value}
            style={{ textTransform: "capitalize" }}
          >
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BorderTypeSelect;
