import React, { useEffect, useState } from "react";
import EmailConfiglist from "./EmailConfiglist";
import EmailConfigmodal from "./EmailConfigmodal";
import "./EmailConfig.scss";
import {
  AxiosResponse,
  deleteEmailConfigByID,
  getAllEmailConfig,
} from "./service";
import { State } from "../EmailBook/EmailBook";
import { Alert, Snackbar } from "@mui/material";

export default function EmailConfig() {
  const [open, setOpen] = React.useState(false);
  const [emailConfigData, setEmailConfigData] = useState<any[]>([]);
  const [selectedEmailConfigData, setSelectedEmailConfigData] = useState<any>();
  const [notificationState, setNotificationState] = useState<State>({
    open: false,
    message: "",
    isError: false,
    vertical: "top",
    horizontal: "center",
    severity: "success",
  });

  useEffect(() => {
    reloadData();
  }, []);

  const showNotification = (message: string, isError: boolean) => {
    setNotificationState({
      ...notificationState,
      open: true,
      message,
      isError,
      severity: isError ? "error" : "success",
    });
  };

  const closeNotification = () => {
    setNotificationState({
      ...notificationState,
      open: false,
      message: "",
      isError: false,
    });
  };

  const reloadData = async () => {
    const response: AxiosResponse = await getAllEmailConfig({
      page: 0,
      limit: 100,
    });

    if (!response.error) {
      await setEmailConfigData(response.data);
    } else {
    }
  };
  const handleModalOpen = (data: any) => {
    setOpen(true);

    if (data) {
      const rowData = {
        email_id: data._id,
        fromName: "esender",
        host: data?.smtp?.host,
        email: data?.smtp?.username,
        username: data?.smtp?.username,
        password: data?.smtp?.password,
        port: data?.smtp?.port,
        ssl: data?.smtp?.ssl,
      };

      setSelectedEmailConfigData(rowData);
    } else {
      const rowData = {
        email_id: "",
        fromName: "esender",
        host: "",
        email: "",
        username: "",
        password: "",
        port: "",
        ssl: false,
      };
      setSelectedEmailConfigData(rowData);
    }
  };
  const handleModalClose = () => {
    setOpen(false);
    setSelectedEmailConfigData(null);
  };

  const deleteEmailConfig = async (id: any) => {
    const response: AxiosResponse = await deleteEmailConfigByID({
      email_id: id,
    });

    if (!response.error) {
      showNotification("Email configuration deleted sucessfully.", false);
      reloadData();
    } else {
      showNotification(response.error.status, true);
      reloadData();
    }
  };

  return (
    <div className="email-config-area">
      <EmailConfigmodal
        data={selectedEmailConfigData}
        reloadData={reloadData}
        showNotification={showNotification}
        handleModalOpen={handleModalOpen}
        handleModalClose={handleModalClose}
        isOpen={open}
      />
      <EmailConfiglist
        deleteEmailConfig={deleteEmailConfig}
        emailConfigData={emailConfigData}
        handleModalOpen={handleModalOpen}
      />

      <Snackbar
        anchorOrigin={{
          vertical: notificationState.vertical,
          horizontal: notificationState.horizontal,
        }}
        open={notificationState.open}
        onClose={closeNotification}
        autoHideDuration={6000}
      >
        <Alert
          onClose={closeNotification}
          severity={notificationState.severity}
          sx={{ width: "100%" }}
        >
          {notificationState.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
