import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { AxiosResponse, getAllContacts } from "./service";
import { getAllGroups } from "../Groups/service";
import EmailBooklist from "../EmailBook/EmailBooklist";
import { GridColDef } from "@mui/x-data-grid";
import { CampaignDataItem } from "./CreateCampaign";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type ToSettingsProps = {
  campaignData: CampaignDataItem;
  contactData: any[];
  groupData: any[];
  setCampaignData: (value: CampaignDataItem) => void;
};

const ToSettings = ({
  campaignData,
  setCampaignData,
  contactData,
  groupData,
}: ToSettingsProps) => {
  const [selectedGroupData, setSelectedGroupData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const reloadData = async () => {
    setLoading(true);
    const groupInContacts = campaignData.recipients.flatMap(
      (item) =>
        contactData.find((data) => data.email === item.email)?.groups ?? []
    );

    await setSelectedGroupData(
      groupData.filter((item) => groupInContacts.includes(item._id))
    );
    setLoading(false);
  };

  useEffect(() => {
    reloadData();
  }, []);

  const handleChange = (event: SelectChangeEvent<typeof selectedGroupData>) => {
    const {
      target: { value },
    } = event;

    setSelectedGroupData([...(value as any[])]);
  };

  const columns: GridColDef[] = [
    { field: "firstName", headerName: "First name", width: 150 },
    { field: "lastName", headerName: "Last name", width: 150 },
    { field: "email", headerName: "Email", flex: 1, minWidth: 200 },
    { field: "phone", headerName: "Phone No", width: 200 },
    {
      field: "groups",
      headerName: "Group",
      flex: 1,
      minWidth: 200,
      renderCell: (params: any) => (
        <>
          {params.row.groups.map((group: string) => (
            <Chip label={groupData.find((gp) => gp._id === group)?.name} />
          ))}
        </>
      ),
    },
  ];

  return (
    <div className="campaign-settings-area">
      <div className="campaign-settings-area--title">To Settings</div>
      <div className="campaign-settings-area--content">
        <div>
          <FormControl sx={{ mb: 1, width: 300 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Groups
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              multiple
              value={selectedGroupData}
              onChange={handleChange}
              input={<OutlinedInput label="Groups" />}
              MenuProps={MenuProps}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value._id} label={value.name} />
                  ))}
                </Box>
              )}
            >
              {groupData.map((group) => (
                <MenuItem key={group._id} value={group}>
                  <Checkbox
                    checked={selectedGroupData.some(
                      (gp) => gp._id === group._id
                    )}
                  />
                  <ListItemText primary={group.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <EmailBooklist
            contactsData={contactData.filter((contact) =>
              selectedGroupData.some((group) =>
                contact.groups.includes(group._id)
              )
            )}
            isEditable={campaignData.id === "new"}
            onRowSelection={(rowSelected) => {
              if (!loading && campaignData.id === "new") {
                const selectedContact = rowSelected.map((contactId) => {
                  const contact = contactData.find(
                    (contact) => contact.id === contactId
                  );

                  return {
                    id: contact.id,
                    email: contact.email,
                    first_name: contact.firstName,
                    last_name: contact.lastName,
                    var_1: "",
                    var_2: "",
                    var_3: "",
                    var_4: "",
                    var_5: "",
                    var_6: "",
                    var_7: "",
                    var_8: "",
                    var_9: "",
                    var_10: "",
                  };
                });

                setCampaignData({
                  ...campaignData,
                  recipients: selectedContact,
                });
              }
            }}
            selectedContacts={campaignData.recipients.flatMap(
              (item) =>
                contactData.find((contact) => contact.email === item.email)?._id
            )}
            columns={columns}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default ToSettings;
