import React from "react";
import "./ComponentSelect.scss";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { componentList } from "../../../ComponentList/ComponentListData";

interface ComponentSelectProps {
  value: string;
  onChange: (value: string) => void;
}

const ComponentSelect = ({ value, onChange }: ComponentSelectProps) => {
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: "98%" }}>
      <InputLabel id="component-type-select-label">Component Type</InputLabel>
      <Select
        labelId="component-type-select-label"
        id="component-type-select"
        value={value === "no-component" ? "" : value}
        onChange={(event: SelectChangeEvent) => onChange(event.target.value)}
        label="Component Type"
      >
        {/* <MenuItem value="no-component" key="no-component">
          None
        </MenuItem> */}
        {componentList.map(
          (component) =>
            component.id !== "columns" && (
              <MenuItem value={component.id} key={component.id}>
                {component.name}
              </MenuItem>
            )
        )}
      </Select>
    </FormControl>
  );
};

export default ComponentSelect;
