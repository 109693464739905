const MenuComponent = ({ style, properties }: any) => {
  const { menuItems } = properties;

  const TextDivStyle = {
    paddingTop: `${style.paddingTop}px`,
    paddingRight: `${style.paddingRight}px`,
    paddingBottom: `${style.paddingBottom}px`,
    paddingLeft: `${style.paddingLeft}px`,
    marginTop: `${style.marginTop}px`,
    marginRight: `${style.marginRight}px`,
    marginBottom: `${style.marginBottom}px`,
    marginLeft: `${style.marginLeft}px`,
    color: style.color,
    lineHeight: `${style.lineHeight}px`,
    textAlign: style.textAlign,
    justifyContent: style.textAlign,
    fontSize: `${style.fontSize}px`,
    fontWeight: style.fontWeight,
    fontFamily: style.fontFamily,
    display: "flex",
    gap: `${style.gap}px`,
    flexDirection: style.flexDirection,
    flexWrap: "wrap" as any,
    backgroundColor: style.backgroundColor,
    border: `${style.borderSize}px ${style.borderType} ${style.borderColor}`,
    zIndex: style.zIndex,
  };

  const LinkStyle = {
    color: style.color,
    lineHeight: `${style.lineHeight}px`,
    fontSize: `${style.fontSize}px`,
    fontWeight: style.fontWeight,
    fontFamily: style.fontFamily,
    textDecoration: style.textDecoration,
  };

  return (
    <div style={{ ...TextDivStyle, position: "relative" }}>
      {menuItems.map((item: any) => (
        <a
          key={item.id}
          href={item.url}
          style={{ ...LinkStyle }}
          target="_blank"
          rel="noreferrer"
        >
          {item.name}
        </a>
      ))}
    </div>
  );
};

export default MenuComponent;
