import axios from "axios";

export const axiosPost = (url: string, body: any, headers: any = {}) => {
  return axios.post(`https://esender.in/api/${url}`, body, {
    headers: {
      Authorization: getToken(),
      ...headers,
    },
  });
};

export const axiosGet = (url: string) => {
  return axios.get(`https://esender.in/api/${url}`, {
    headers: {
      Authorization: getToken(),
    },
  });
};

export const getToken = () => {
  return `Bearer ${localStorage.getItem("token")}`;
};
