import { axiosGet, axiosPost } from "../shared/axiosService";
import { CampaignDataItem } from "./CreateCampaign";

export type AxiosResponse = {
  data: any[];
  error: any;
};

export const getAllCampaigns = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("campaign/all", body);
    if (res.data.status) {
      response.data = res.data.campaigns;
    } else {
      response.error = res.data.message;
    }
  } catch (error) {
    console.error(error);
    response.error = error;
  }

  return response;
};

export const getAllCampaignTasks = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("campaign/tasks", body);
    if (res.data.status) {
      response.data = res.data.tasks;
    } else {
      response.error = res.data.message;
    }
  } catch (error) {
    console.error(error);
    response.error = error;
  }

  return response;
};

export const getCampaignByID = async (id: string) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosGet(`campaign/id/${id}`);
    if (res.data.status) {
      response.data = [mapCampaignDataItem(res.data.campaign)];
    } else {
      response.error = res.data.message;
    }
  } catch (error) {
    console.error(error);
    response.error = error;
  }

  return response;
};

const mapCampaignDataItem = (data: any): CampaignDataItem => {
  return {
    id: data._id,
    name: data.name,
    template_id: data.templateId,
    from: {
      email: data.from.email,
      name: data.from.name,
      reply_to_email: data.from.replyToEmail,
      reply_to_name: data.from.replyToName,
    },
    accounts: data.accounts,
    subject: {
      name: data.subject.name,
      preview: data.subject.preview,
    },
    recipients: data.recipients.map((item: any) => ({ ...item, id: item._id })),
  };
};

export const updateCampaignStatus = async (operation: string, body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost(`campaign/${operation}`, body);
    if (res.data.status) {
      response.data = [];
    } else {
      response.error = res.data.message;
    }
  } catch (error) {
    console.error(error);
    response.error = error;
  }

  return response;
};

export const getAllContacts = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("contact/all", body);
    if (res.data.status) {
      response.data = res.data.contacts;
    } else {
      response.error = res.data.message;
    }
  } catch (error) {
    console.error(error);
    response.error = error;
  }

  return response;
};

export const createCampaign = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("campaign/create", body);

    if (res.data.status) {
      response.data = [res.data.campaign_id];
    } else {
      response.error = res.data.message;
    }
  } catch (error) {
    console.error(error);
    response.error = error;
  }

  return response;
};

export const addContact = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("contact/add", body);

    if (res.data.status) {
      response.data = res.data.contacts;
    } else {
      response.error = res.data.message;
    }
  } catch (error) {
    console.error(error);
    response.error = error;
  }

  return response;
};

export const saveContact = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("contact/save", body);

    if (res.data.status) {
      response.data = res.data.contacts;
    } else {
      response.error = res.data.message;
    }
  } catch (error) {
    console.error(error);
    response.error = error;
  }

  return response;
};

export const deleteCampaignByID = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("campaign/delete", body);
    if (res.data.status) {
      response.data = [];
    } else {
      response.error = res.data.message;
    }
  } catch (error) {
    console.error(error);
    response.error = error;
  }

  return response;
};
