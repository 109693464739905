import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { Box } from "@mui/system";

import "./SideBar.scss";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ViewQuiltOutlinedIcon from "@mui/icons-material/ViewQuiltOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import { useLocation, useNavigate } from "react-router-dom";
const SideBarMenu = [
  {
    id: "dashboard",
    label: "Dashboard",
    to: "/",
    icon: <DashboardOutlinedIcon />,
  },
  {
    id: "template",
    label: "Templates",
    to: "/templates",
    icon: <ViewQuiltOutlinedIcon />,
  },
  {
    id: "contact",
    label: "Contacts",
    to: "/contacts",
    icon: <ContactsOutlinedIcon />,
  },
  {
    id: "groups",
    label: "Groups",
    to: "/groups",
    icon: <Groups2OutlinedIcon />,
  },
  {
    id: "setting",
    label: "Settings",
    to: "/settings",
    icon: <SettingsOutlinedIcon />,
  },
];

const drawerWidth = 240;

const SideBar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  if (pathname.includes("/template/")) {
    return null;
  }

  return (
    <Drawer
      variant="permanent"
      className="side-bar"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          {SideBarMenu.map((item) => (
            <ListItem key={item.id} disablePadding>
              <ListItemButton
                selected={pathname === item.to}
                onClick={() => navigate(item.to)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default SideBar;
