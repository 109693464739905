import TemplateList from "../Template/TemplateList";
import { CampaignDataItem } from "./CreateCampaign";

type DesignServicesProps = {
  campaignData: CampaignDataItem;
  setCampaignData: (value: CampaignDataItem) => void;
};

const DesignSettings = ({
  campaignData,
  setCampaignData,
}: DesignServicesProps) => {
  return (
    <div className="campaign-settings-area">
      <div className="campaign-settings-area--title">Design Settings</div>
      <div className="campaign-settings-area--content">
        <TemplateList
          onlySelect={true}
          selectedTemplateId={campaignData?.template_id}
          getSelectedTemplate={(templateId: string) => {
            if (campaignData.id === "new") {
              setCampaignData({
                ...campaignData,
                template_id: templateId,
              });
            }
          }}
        />
      </div>
    </div>
  );
};

export default DesignSettings;
