import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";

type EmailBookListProps = {
  contactsData: any;
  columns: GridColDef[];
  loading?: boolean;
  selectedContacts?: string[];
  onRowSelection?: (values: any[]) => void;
  isEditable?: boolean;
  isCampaignCreation?: boolean;
};

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "300px",
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <img
        src="/images/no-data.png"
        alt="No data Present"
        className="no-data-image"
      />
    </StyledGridOverlay>
  );
}

const EmailBooklist = ({
  contactsData,
  columns,
  loading,
  onRowSelection,
  selectedContacts = [],
  isEditable = false,
  isCampaignCreation = true,
}: EmailBookListProps) => {
  return (
    <div
      style={{ width: "100%", height: "100%" }}
      className={`${contactsData?.length > 0 ? "with-data" : "no-data"}`}
    >
      <DataGrid
        checkboxSelection={isCampaignCreation}
        rows={contactsData}
        columns={columns}
        paginationModel={{ pageSize: 25, page: 0 }}
        pageSizeOptions={[5, 10, 25]}
        autoHeight
        loading={loading}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        onRowSelectionModelChange={(newRowSelectionModel: any) => {
          if (isEditable) {
            onRowSelection?.(newRowSelectionModel);
          }
        }}
        rowSelectionModel={
          contactsData && contactsData.length > 0 ? selectedContacts : []
        }
      />
    </div>
  );
};

//react typescript code for excel file download on button click

export default EmailBooklist;
