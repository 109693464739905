import * as React from "react";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";

type CampaignListProps = {
  campaignData: any;
  columns: GridColDef[];
  loading?: boolean;
  onRowSelection?: (values: any[]) => void;
};

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "300px",
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <img
        src="/images/no-data.png"
        alt="No data Present"
        className="no-data-image"
      />
    </StyledGridOverlay>
  );
}

const CampaignList = ({
  campaignData,
  columns,
  loading,
  onRowSelection,
}: CampaignListProps) => {
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  return (
    <div
      style={{ width: "100%", height: "100%" }}
      className={`${campaignData?.length > 0 ? "with-data" : "no-data"}`}
    >
      <DataGrid
        rows={campaignData}
        columns={columns}
        paginationModel={{ pageSize: 25, page: 0 }}
        pageSizeOptions={[5, 10, 25]}
        autoHeight
        loading={loading}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        onRowSelectionModelChange={(newRowSelectionModel: any) => {
          setRowSelectionModel(newRowSelectionModel);
          onRowSelection?.(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
      />
    </div>
  );
};

//react typescript code for excel file download on button click

export default CampaignList;
