import {
  faColumns,
  faFont,
  faHeading,
  faImage,
  faSquare,
  faUsers,
  faMinus,
  faCode,
  faBars,
  faBell,
  faBellSlash,
} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuid } from "uuid";
import TextComponent from "../Builder/Components/Text";
import ImageComponent from "../Builder/Components/Image";
import DividerComponent from "../Builder/Components/Divider";
import ButtonComponent from "../Builder/Components/Button";
import ColumnsComponent from "../Builder/Components/Columns";
import HeadingComponent from "../Builder/Components/Heading";
import SocialComponent from "../Builder/Components/Social";
import SocialShareComponent from "../Builder/Components/SocialShare";
import HtmlSection from "../Builder/Components/HtmlSection";
import MenuComponent from "../Builder/Components/MenuComponent";
import ImageTextComponent from "../Builder/Components/ImageText";
import UnsubscibeComponent from "../Builder/Components/Unsubscibe";

export interface ComponentItem {
  id: string;
  name: string;
  icon: any;
  componentRender: () => JSX.Element | null;
  props: any;
}

export const NO_COLUMN_ITEM = {
  name: "No Column",
  componentRender: null,
  props: {
    properties: {
      componentType: "no-component",
    },
    style: {
      color: "#000000",
      paddingTop: 10,
      paddingRight: 10,
      paddingBottom: 10,
      paddingLeft: 10,
      marginTop: 10,
      marginRight: 10,
      marginBottom: 10,
      marginLeft: 10,
    },
  },
};
export const SOCIAL_MEDIA_ITEM = {
  name: "social-media",
  iconUrl: "",
  url: "",
};
export const MENU_ITEM = { name: "Link", url: "" };

export const componentList = [
  {
    id: "columns",
    name: "Columns",
    icon: faColumns,
    componentRender: ColumnsComponent,
    props: {
      properties: {
        noOfColumns: 2,
        columnWidths: [50, 50],
        items: [
          {
            id: uuid(),
            ...NO_COLUMN_ITEM,
          },
          {
            id: uuid(),
            ...NO_COLUMN_ITEM,
          },
        ],
      },
      style: {
        backgroundColor: "#ffffff",
        color: "#000000",
        gap: 5,
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
        marginLeft: 10,
        borderColor: "#dcdcdc",
        borderSize: 1,
        borderType: "solid",
        zIndex: 0,
      },
    },
  },
  {
    id: "heading",
    name: "Heading",
    icon: faHeading,
    componentRender: HeadingComponent,
    props: {
      properties: {
        text: "<h1>Heading 1</h1>",
      },
      style: {
        backgroundColor: "#ffffff",
        color: "#000000",
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
        marginLeft: 10,
        textAlign: "left",
        fontSize: 20,
        fontWeight: 100,
        fontFamily: "Arial",
        borderColor: "#dcdcdc",
        borderSize: 1,
        borderType: "solid",
        borderRadius: 0,
        zIndex: 0,
      },
    },
  },
  {
    id: "text",
    name: "Text",
    icon: faFont,
    componentRender: TextComponent,
    props: {
      properties: {
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      },
      style: {
        backgroundColor: "#ffffff",
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
        marginLeft: 10,
        color: "#000000",
        lineHeight: 20,
        textAlign: "left",
        fontSize: 15,
        fontWeight: 100,
        fontFamily: "Arial",
        borderColor: "#dcdcdc",
        borderSize: 1,
        borderType: "solid",
        borderRadius: 0,
        zIndex: 0,
      },
    },
  },
  {
    id: "image",
    name: "Image",
    icon: faImage,
    componentRender: ImageComponent,
    props: {
      style: {
        backgroundColor: "#ffffff",
        color: "#000000",
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
        marginLeft: 10,
        textAlign: "center",
        width: 100,
        borderColor: "#dcdcdc",
        borderSize: 1,
        borderType: "solid",
        borderRadius: 0,
        zIndex: 0,
      },
      properties: {
        mediaType: "image",
        src: "",
        altText: "Alternate Text",
        url: "",
        targetBlank: true,
      },
    },
  },
  {
    id: "icon",
    name: "Icon",
    icon: faImage,
    componentRender: ImageComponent,
    props: {
      style: {
        backgroundColor: "#ffffff",
        color: "#000000",
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
        marginLeft: 10,
        textAlign: "center",
        width: 100,
        borderColor: "#dcdcdc",
        borderSize: 1,
        borderType: "solid",
        borderRadius: 0,
        zIndex: 0,
      },
      properties: {
        mediaType: "icon",
        src: "",
        altText: "Alternate Text",
        url: "",
        targetBlank: true,
      },
    },
  },
  {
    id: "button",
    name: "Button",
    icon: faSquare,
    componentRender: ButtonComponent,
    props: {
      style: {
        backgroundColor: "#ffffff",
        buttonBackgroundColor: "#000000",
        color: "#ffffff",
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
        marginLeft: 10,
        buttonBorderRadius: 5,
        lineHeight: 40,
        buttonAlign: "center",
        fontSize: 15,
        fontFamily: "Arial",
        buttonWidth: 20,
        borderColor: "#dcdcdc",
        borderSize: 1,
        borderType: "solid",
        borderRadius: 0,
        zIndex: 0,
      },
      properties: {
        url: "",
        targetBlank: true,
        label: "Click Me",
      },
    },
  },
  {
    id: "divider",
    name: "Divider",
    icon: faMinus,
    componentRender: DividerComponent,
    props: {
      style: {
        backgroundColor: "#ffffff",
        color: "#000000",
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
        marginLeft: 10,
        width: 100,
        size: 1,
        dividerStyle: "solid",
        textAlign: "left",
        borderColor: "#dcdcdc",
        borderSize: 1,
        borderType: "solid",
        borderRadius: 0,
        zIndex: 0,
      },
    },
  },
  {
    id: "html",
    name: "Html",
    icon: faCode,
    componentRender: HtmlSection,
    props: {
      style: {
        backgroundColor: "#ffffff",
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
        marginLeft: 10,
        borderColor: "#dcdcdc",
        borderSize: 1,
        borderType: "solid",
        borderRadius: 0,
        zIndex: 0,
      },
      properties: {
        htmlCode: "<strong>Hello World</strong>",
      },
    },
  },
  {
    id: "menu",
    name: "Menu",
    icon: faBars,
    componentRender: MenuComponent,
    props: {
      properties: {
        menuItems: [
          { id: uuid(), ...MENU_ITEM },
          { id: uuid(), ...MENU_ITEM },
        ],
      },
      style: {
        backgroundColor: "#ffffff",
        color: "#000000",
        flexDirection: "row",
        gap: 10,
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
        marginLeft: 10,
        fontSize: 15,
        fontWeight: 100,
        fontFamily: "Arial",
        textAlign: "left",
        textDecoration: "none",
        borderColor: "#dcdcdc",
        borderSize: 1,
        borderType: "solid",
        borderRadius: 0,
        zIndex: 0,
      },
    },
  },
  {
    id: "social",
    name: "Social",
    icon: faUsers,
    componentRender: SocialComponent,
    props: {
      properties: {
        socialItems: [],
      },
      style: {
        backgroundColor: "#ffffff",
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
        marginLeft: 10,
        textAlign: "left",
        iconSpacing: 5,
        borderColor: "#dcdcdc",
        borderSize: 1,
        borderType: "solid",
        borderRadius: 0,
        zIndex: 0,
      },
    },
  },
  {
    id: "social-share",
    name: "Social Share",
    icon: faUsers,
    componentRender: SocialShareComponent,
    props: {
      properties: {
        socialItems: [],
      },
      style: {
        backgroundColor: "#ffffff",
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
        marginLeft: 10,
        textAlign: "left",
        iconSpacing: 5,
        borderColor: "#dcdcdc",
        borderSize: 1,
        borderType: "solid",
        borderRadius: 0,
        zIndex: 0,
      },
    },
  },
  {
    id: "image-text",
    name: "Image Text",
    icon: faUsers,
    componentRender: ImageTextComponent,
    props: {
      properties: {
        src: "",
        altText: "Alternate Text",
        url: "",
        targetBlank: true,
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      },
      style: {
        backgroundColor: "#ffffff",
        flexDirection: "row",
        flexAlign: "center",
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
        marginLeft: 10,
        textAlign: "left",
        imgAlign: "center",
        gap: 10,
        imgSize: 100,
        color: "#000000",
        lineHeight: 20,
        fontSize: 15,
        fontWeight: 100,
        fontFamily: "Arial",
        imgColumnWidth: 100,
        textColumnWidth: 100,
        borderColor: "#dcdcdc",
        borderSize: 1,
        borderType: "solid",
        borderRadius: 0,
        zIndex: 0,
      },
    },
  },
  {
    id: "unsuscribe",
    name: "Unsubscribe",
    icon: faBellSlash,
    componentRender: UnsubscibeComponent,
    props: {
      properties: {
        text: "Click here to unsubscribe ",
      },
      style: {
        backgroundColor: "#ffffff",
        color: "#000000",
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
        marginLeft: 10,
        lineHeight: 20,
        textAlign: "left",
        fontSize: 15,
        fontWeight: 100,
        fontFamily: "Arial",
        borderColor: "#dcdcdc",
        borderSize: 1,
        borderType: "solid",
        borderRadius: 0,
        zIndex: 0,
      },
    },
  },
] as ComponentItem[];

export const componentRendererMap = new Map();
componentRendererMap.set("Columns", ColumnsComponent);
componentRendererMap.set("Heading", HeadingComponent);
componentRendererMap.set("Text", TextComponent);
componentRendererMap.set("Image Text", ImageTextComponent);
componentRendererMap.set("Social Share", SocialShareComponent);
componentRendererMap.set("Social", SocialComponent);
componentRendererMap.set("Menu", MenuComponent);
componentRendererMap.set("Html", HtmlSection);
componentRendererMap.set("Divider", DividerComponent);
componentRendererMap.set("Button", ButtonComponent);
componentRendererMap.set("Image", ImageComponent);
componentRendererMap.set("Icon", ImageComponent);
componentRendererMap.set("Unsubscribe", UnsubscibeComponent);

export const globalStyleComponentData = {
  id: "globalStyles",
  name: "Global Style",
  icon: null,
  componentRender: () => null,
  props: {
    properties: {
      backgroundImage: "",
    },
    style: {
      backgroundColor: "#ffffff",
      paddingTop: 10,
      paddingRight: 10,
      paddingBottom: 10,
      paddingLeft: 10,
      marginTop: 10,
      marginRight: 10,
      marginBottom: 10,
      marginLeft: 10,
      color: "#000000",
      lineHeight: 20,
      textAlign: "left",
      fontSize: 20,
      fontWeight: 600,
      fontFamily: "Arial",
    },
  },
} as ComponentItem;
