import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import "./EmailBook.scss";
import Button from "@mui/material/Button";
import EmailBooklist from "./EmailBooklist";
import {
  Alert,
  Chip,
  IconButton,
  Snackbar,
  SnackbarOrigin,
} from "@mui/material";
import "./EmailBook.scss";
import EmailBookModal from "./EmailBookModal";
import { GridColDef } from "@mui/x-data-grid";
import { read, utils } from "xlsx";
import { validateContacts } from "./utils";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../shared/ConfirmationDialog";
import { AxiosResponse, deleteContactByID, getAllContacts } from "./service";
import EmailBookEditModal from "./EmailBookEditModal";
import { getAllGroups, getGroupById } from "../Groups/service";
import { useFetch } from "use-http";

export interface State extends SnackbarOrigin {
  open: boolean;
  message: string;
  isError: boolean;
  severity?: any;
}

const EmailBook = () => {
  const [contactData, setContactData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [contactDataToAdd, setContactDataToAdd] = useState<any[]>([]);
  const [groupList, setGroupList] = useState<any[]>([]);

  useEffect(() => {
    reloadData();
    reloadGroupData();
  }, []);

  const reloadData = async () => {
    setLoading(true);
    const response: AxiosResponse = await getAllContacts({
      page: 0,
      limit: 100,
    });

    if (!response.error) {
      await setContactData(response.data);
    }

    setLoading(false);
  };

  const reloadGroupData = async () => {
    const response: AxiosResponse = await getAllGroups({
      page: 0,
      limit: 100,
    });

    if (!response.error) {
      await setGroupList(response.data);
    } else {
    }
  };

  const [isOpen, setOpen] = useState(false);
  const [isImport, setIsImport] = useState(false);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = React.useState(false);
  const [groupListData, setGroupListData] = useState<Map<string, string>>();

  const [selectedParams, setSelectedParams] = useState<any | null>(null);
  const [notificationState, setNotificationState] = useState<State>({
    open: false,
    message: "",
    isError: false,
    vertical: "top",
    horizontal: "center",
    severity: "success",
  });

  const showNotification = (message: string, isError: boolean) => {
    setNotificationState({
      ...notificationState,
      open: true,
      message,
      isError,
      severity: isError ? "error" : "success",
    });
  };

  const closeNotification = () => {
    setNotificationState({
      ...notificationState,
      open: false,
      message: "",
      isError: false,
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsImport(false);
  };

  const handleEditModalOpen = () => {
    setEditModalOpen(true);
  };
  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const deleteContact = async (id: any) => {
    const response: AxiosResponse = await deleteContactByID({
      contact_id: id,
    });

    if (!response.error) {
      showNotification("Contact Sucessfully deleted", false);
      reloadData();
    } else {
      showNotification(response.error.status, true);
      reloadData();
    }
  };

  useEffect(() => {
    const getGroupData = async () => {
      const response: AxiosResponse = await getAllGroups({
        page: 0,
        limit: 100,
      });

      const groupNames = new Map();
      if (!response.error) {
        response.data.reduce((acc, cur) => {
          groupNames.set(cur.id, cur.name);
        }, groupNames);
        await setGroupListData(groupNames);
        console.log(groupNames);
      }
    };

    getGroupData();
  }, []);

  const columns: GridColDef[] = [
    { field: "firstName", headerName: "First name", width: 150 },
    { field: "lastName", headerName: "Last name", width: 150 },
    { field: "email", headerName: "Email", flex: 1, minWidth: 200 },
    { field: "phone", headerName: "Phone No", width: 200 },
    {
      field: "groups",
      headerName: "Group",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <>
          {params.row.groups.map((group: string) => (
            <Chip label={groupListData?.get(group)} />
          ))}
        </>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={(event: any) => {
              event.stopPropagation();
              params.row.groups.map((group: string) =>
                groupListData?.get(group)
              );
              setSelectedParams({
                ...params.row,
                groups: params.row.groups.map((group: string) =>
                  groupListData?.get(group)
                ),
              });
              handleEditModalOpen();
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={(event: any) => {
              event.stopPropagation();
              setConfirmationDialogOpen(true);
              setSelectedParams(params);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const handleImport = ($event: any) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target?.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);

          if (validateContacts(rows)) {
            const newRows = rows.map((row: any) => ({ ...row, id: uuid() }));
            setContactDataToAdd(newRows);
            setIsImport(true);
            handleOpen();
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <div className="email-book-area">
      <div className="email-book-area--list">
        <div className="email-book-area--title">
          <span>Contacts</span>
          <div className="email-book-area--action">
            <Button
              variant="outlined"
              component="label"
              style={{ marginRight: "20px" }}
            >
              Import
              <input
                hidden
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                type="file"
                onChange={handleImport}
              />
            </Button>

            <Button variant="outlined" onClick={handleOpen}>
              Add
            </Button>
            <EmailBookModal
              isOpen={isOpen}
              handleClose={handleClose}
              reloadData={reloadData}
              reloadGroupData={reloadGroupData}
              isImport={isImport}
              contactDataToAdd={contactDataToAdd}
              setContactDataToAdd={setContactDataToAdd}
              showNotification={showNotification}
              groupList={groupList}
            />
            <EmailBookEditModal
              data={selectedParams}
              reloadData={reloadData}
              handleModalClose={handleEditModalClose}
              isOpen={isEditModalOpen}
              showNotification={showNotification}
              reloadGroupData={reloadGroupData}
              groupList={groupList}
            />
            <ConfirmationDialog
              desription="Are you sure you want to delete this contact?"
              isOpen={isConfirmationDialogOpen}
              handleClose={() => {
                setConfirmationDialogOpen(false);
                setSelectedParams(null);
              }}
              handleOnAgree={() => {
                setConfirmationDialogOpen(false);
                deleteContact(selectedParams.id);
                setSelectedParams(null);
              }}
            />
          </div>
        </div>
        <EmailBooklist
          contactsData={contactData}
          columns={columns}
          loading={loading}
          isCampaignCreation={false}
        />
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: notificationState.vertical,
          horizontal: notificationState.horizontal,
        }}
        open={notificationState.open}
        onClose={closeNotification}
        autoHideDuration={6000}
      >
        <Alert
          onClose={closeNotification}
          severity={notificationState.severity}
          sx={{ width: "100%" }}
        >
          {notificationState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EmailBook;
