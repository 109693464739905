import { TextField } from "@mui/material";
import { CampaignDataItem } from "./CreateCampaign";

type FromSettingsProps = {
  campaignData: CampaignDataItem;
  setCampaignData: (value: CampaignDataItem) => void;
};
const FromSettings = ({ campaignData, setCampaignData }: FromSettingsProps) => {
  return (
    <div className="campaign-settings-area">
      <div className="campaign-settings-area--title">From Settings</div>
      <div className="campaign-settings-area--content">
        <TextField
          name="from-email"
          value={campaignData.from.email}
          label="Email Address"
          variant="standard"
          disabled={campaignData.id !== "new"}
          className="campaign-settings-area--input"
          onChange={(event) =>
            setCampaignData({
              ...campaignData,
              from: { ...campaignData.from, email: event.target.value },
            })
          }
        />
        <TextField
          name="from-name"
          value={campaignData.from.name}
          label="Name"
          disabled={campaignData.id !== "new"}
          variant="standard"
          className="campaign-settings-area--input"
          onChange={(event) =>
            setCampaignData({
              ...campaignData,
              from: { ...campaignData.from, name: event.target.value },
            })
          }
        />
        <TextField
          name="replay-to-email"
          value={campaignData.from.reply_to_email}
          label="Reply to Email Address"
          variant="standard"
          disabled={campaignData.id !== "new"}
          className="campaign-settings-area--input"
          onChange={(event) =>
            setCampaignData({
              ...campaignData,
              from: {
                ...campaignData.from,
                reply_to_email: event.target.value,
              },
            })
          }
        />
        <TextField
          name="replay-to-name"
          disabled={campaignData.id !== "new"}
          value={campaignData.from.reply_to_name}
          label="Reply to Name"
          variant="standard"
          className="campaign-settings-area--input"
          onChange={(event) =>
            setCampaignData({
              ...campaignData,
              from: { ...campaignData.from, reply_to_name: event.target.value },
            })
          }
        />
      </div>
    </div>
  );
};

export default FromSettings;
