import { Menu, MenuItem, Typography } from "@mui/material";

const menuList = [
  { label: "Notification 1", route: "" },
  { label: "notification 2", route: "" },
];
const NotificationMenu = ({
  anchorElNotification,
  handleNotificationClose,
}: any) => {
  return (
    <Menu
      className="notification-menu"
      sx={{ mt: "50px" }}
      id="menu-appbar"
      anchorEl={anchorElNotification}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElNotification)}
      onClose={handleNotificationClose}
    >
      {menuList.map((menuItem) => (
        <MenuItem
          key={menuItem.label}
          onClick={() => {
            handleNotificationClose(menuItem.route);
          }}
        >
          <Typography textAlign="center">{menuItem.label}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default NotificationMenu;
