import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import {
  Alert,
  Chip,
  IconButton,
  Snackbar,
  SnackbarOrigin,
} from "@mui/material";
import "./Campaign.scss";
import { useNavigate } from "react-router-dom";

import {
  AxiosResponse,
  deleteCampaignByID,
  getAllCampaignTasks,
  getAllCampaigns,
  updateCampaignStatus,
} from "./service";
import { GridColDef } from "@mui/x-data-grid";
import CampaignList from "./CampaignList";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import PauseIcon from "@mui/icons-material/Pause";
import ConfirmationDialog from "../shared/ConfirmationDialog";
export interface State extends SnackbarOrigin {
  open: boolean;
  message: string;
  isError: boolean;
  severity?: any;
}

const Campaign = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [selectedParams, setSelectedParams] = useState<any | null>(null);
  const [campaignData, setCampaignData] = useState<any[]>([]);

  const [notificationState, setNotificationState] = useState<State>({
    open: false,
    message: "",
    isError: false,
    vertical: "top",
    horizontal: "center",
    severity: "success",
  });

  const showNotification = (message: string, isError: boolean) => {
    setNotificationState({
      ...notificationState,
      open: true,
      message,
      isError,
      severity: isError ? "error" : "success",
    });
  };

  const closeNotification = () => {
    setNotificationState({
      ...notificationState,
      open: false,
      message: "",
      isError: false,
    });
  };

  useEffect(() => {
    reloadData();
  }, []);

  const reloadData = async () => {
    setLoading(true);
    const response: AxiosResponse = await getAllCampaigns({
      page: 0,
      limit: 100,
    });

    if (!response.error) {
      const campaignData = response.data;
      console.log(campaignData);

      const tasks = await Promise.all(
        campaignData.map(async (campaign) =>
          getAllCampaignTasks({
            campaign_id: campaign.id,
            limit: 1000,
          })
        )
      );

      const campaignTasks = tasks
        .map((item) => item.data)
        .filter((tasks) => tasks.length > 0);

      const campaignDataWithTasks = campaignData.map((campaign) => {
        const tasks = campaignTasks.find(
          (tasks) => tasks?.length > 0 && tasks[0].campaignId === campaign.id
        );
        return {
          ...campaign,
          tasks,
        };
      });
      await setCampaignData(campaignDataWithTasks);
      console.log(campaignDataWithTasks);
    }

    setLoading(false);
  };

  const deleteCampaign = async (id: any) => {
    const response: AxiosResponse = await deleteCampaignByID({
      campaign_id: id,
    });

    if (!response.error) {
      showNotification("Campaign Successfully deleted", false);
      reloadData();
    } else {
      showNotification(response.error.status, true);
      reloadData();
    }
  };

  const statusMap = [
    "Pending",
    "Sending",
    "Delivered",
    "Paused",
    "Failed",
    "Cancelled",
  ];

  const renderActionButton = (params: any) => {
    const {
      row: { status },
    } = params;

    return (
      <>
        {(status === 0 || status === 3) && (
          <IconButton
            aria-label="start"
            onClick={async (event: any) => {
              event.stopPropagation();
              await updateCampaignProcess("resume", params.row.id);
            }}
          >
            <PlayArrowIcon />
          </IconButton>
        )}
        {status === 1 && (
          <>
            <IconButton
              aria-label="pause"
              onClick={async (event: any) => {
                event.stopPropagation();
                await updateCampaignProcess("pause", params.row.id);
              }}
            >
              <PauseIcon />
            </IconButton>
            <IconButton
              aria-label="cancel"
              onClick={async (event: any) => {
                event.stopPropagation();
                await updateCampaignProcess("cancel", params.row.id);
              }}
            >
              <CloseIcon />
            </IconButton>
          </>
        )}
        <IconButton
          aria-label="edit"
          onClick={(event: any) => {
            event.stopPropagation();
            navigate(`/campaign/${params.row.id}`);
          }}
        >
          <VisibilityRoundedIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={(event: any) => {
            event.stopPropagation();
            setConfirmationDialogOpen(true);
            setSelectedParams(params);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </>
    );
  };

  const updateCampaignProcess = async (status: string, id: string) => {
    const response: AxiosResponse = await updateCampaignStatus(status, {
      campaign_id: id,
    });

    if (!response.error) {
      showNotification(`Campaign ${status} Successfully`, false);
      reloadData();
    } else {
      showNotification(response.error.status, true);
    }
  };

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1, width: 150 },
    { field: "createdAt", headerName: "Created At", width: 200 },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => <>{statusMap[params.row.status]}</>,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      align: "right",
      minWidth: 400,
      headerAlign: "right",
      renderCell: renderActionButton,
    },
  ];

  return (
    <div className="campaign-area">
      <div className="campaign-area--list">
        <div className="campaign-area--title">
          <span>Campaigns</span>
          <div className="campaign-area--action">
            <Button
              variant="outlined"
              onClick={() => navigate("/campaign/new")}
            >
              Add
            </Button>
          </div>
        </div>
        <CampaignList
          campaignData={campaignData}
          columns={columns}
          loading={loading}
          onRowSelection={(values: any[]) => {}}
        />
        <ConfirmationDialog
          desription="Are you sure you want to delete this campaign?"
          isOpen={isConfirmationDialogOpen}
          handleClose={() => {
            setConfirmationDialogOpen(false);
            setSelectedParams(null);
          }}
          handleOnAgree={() => {
            setConfirmationDialogOpen(false);
            deleteCampaign(selectedParams.id);
            setSelectedParams(null);
          }}
        />
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: notificationState.vertical,
          horizontal: notificationState.horizontal,
        }}
        open={notificationState.open}
        onClose={closeNotification}
        autoHideDuration={6000}
      >
        <Alert
          onClose={closeNotification}
          severity={notificationState.severity}
          sx={{ width: "100%" }}
        >
          {notificationState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Campaign;
