import { axiosPost } from "../shared/axiosService";

export type AxiosResponse = {
  data: any[];
  error: any;
};

export const getAllEmailConfig = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("email/all", body);
    if (res.data.status) {
      response.data = res.data.emails;
    } else {
      response.error = res.data.message;
    }
  } catch (error) {
    console.error(error);
    response.error = error;
  }

  return response;
};

export const addEmailConfig = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("email/add", body);
    if (res.data.status) {
      response.data = res.data.contacts;
    } else {
      response.error = res.data.message;
    }
  } catch (error) {
    console.error(error);
    response.error = error;
  }

  return response;
};

export const saveEmailConfig = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("email/save", body);
    if (res.data.status) {
      response.data = res.data.emails;
    } else {
      response.error = res.data.message;
    }
  } catch (error) {
    console.error(error);
    response.error = error;
  }

  return response;
};

export const deleteEmailConfigByID = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("email/delete", body);
    if (res.data.status) {
      response.data = res.data.emails;
    } else {
      response.error = res.data.message;
    }
  } catch (error) {
    console.error(error);
    response.error = error;
  }

  return response;
};
