import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import "./ComponentList.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ComponentItem } from "./ComponentListData";

export interface ComponentListProps {
  components: ComponentItem[];
}
const Item = styled.div<{ isDragging?: boolean }>`
  border: 1px
    ${(props) =>
      props.isDragging ? "dashed #000" : "solid rgb(221, 221, 221)"};
`;

const Clone = styled(Item)`
  ~ div {
    transform: none !important;
  }
`;

export const COMPONENT_LIST_AREA = "component-list-area";

const ComponentList = ({ components }: ComponentListProps) => {
  return (
    <Droppable droppableId={COMPONENT_LIST_AREA} isDropDisabled>
      {(provided) => (
        <div
          className="component-list"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {components.map(({ id, name, icon }, index) => {
            return (
              <Draggable key={id} draggableId={id} index={index}>
                {(provided, snapshot) => (
                  <>
                    <Item
                      className="component-item"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      isDragging={snapshot.isDragging}
                      style={provided.draggableProps.style}
                    >
                      <FontAwesomeIcon className="component-icon" icon={icon} />
                      <span className="component-name">{name}</span>
                    </Item>
                    {snapshot.isDragging && (
                      <Clone className="component-item">
                        <FontAwesomeIcon
                          className="component-icon"
                          icon={icon}
                        />
                        <span className="component-name">{name}</span>
                      </Clone>
                    )}
                  </>
                )}
              </Draggable>
            );
          })}
        </div>
      )}
    </Droppable>
  );
};

export default ComponentList;
