import React from "react";
import "./FlexDirectionInput.scss";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface FlexDirectionInputProps {
  value: string;
  onChange: (value: string) => void;
}

const FlexDirectionInput = ({ value, onChange }: FlexDirectionInputProps) => {
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={value}
        onChange={(event: SelectChangeEvent) =>
          onChange(event.target.value || "arial")
        }
        label="Layout"
      >
        <MenuItem value="row" key="row">
          Horizontal
        </MenuItem>
        <MenuItem value="column" key="column">
          Vertical
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default FlexDirectionInput;
