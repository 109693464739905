import { Button } from "@mui/material";
import "./TextAlignInput.scss";
import AlignVerticalBottomOutlinedIcon from "@mui/icons-material/AlignVerticalBottomOutlined";
import AlignVerticalCenterOutlinedIcon from "@mui/icons-material/AlignVerticalCenterOutlined";
import AlignVerticalTopOutlinedIcon from "@mui/icons-material/AlignVerticalTopOutlined";
export interface FlexAlignInputProps {
  label?: string;
  value: string;
  onChange: (newValue: FLEX_ALIGN) => void;
}

export enum FLEX_ALIGN {
  START = "start",
  CENTER = "center",
  END = "end",
}

const FlexAlignInput = ({ label, value, onChange }: FlexAlignInputProps) => {
  return (
    <div className="text-align-filed">
      {label && <label className="text-align-filed--label">{label}</label>}
      <div className="text-align-filed--content">
        <div
          className={`text-align-filed--button ${
            value === FLEX_ALIGN.START && "active"
          }`}
        >
          <Button onClick={() => onChange(FLEX_ALIGN.START)}>
            <AlignVerticalTopOutlinedIcon />
          </Button>
        </div>
        <div
          className={`text-align-filed--button ${
            value === FLEX_ALIGN.CENTER && "active"
          }`}
        >
          <Button onClick={() => onChange(FLEX_ALIGN.CENTER)}>
            <AlignVerticalCenterOutlinedIcon />
          </Button>
        </div>
        <div
          className={`text-align-filed--button ${
            value === FLEX_ALIGN.END && "active"
          }`}
        >
          <Button onClick={() => onChange(FLEX_ALIGN.END)}>
            <AlignVerticalBottomOutlinedIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FlexAlignInput;
