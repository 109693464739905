import { Autocomplete, Chip, TextField } from "@mui/material";
import { CampaignDataItem } from "./CreateCampaign";
import { AxiosResponse } from "./service";
import { getAllEmailConfig } from "../EmailConfig/service";
import { useEffect, useState } from "react";

type CampaignSettingsProps = {
  campaignData: CampaignDataItem;
  setCampaignData: (value: CampaignDataItem) => void;
};
const CampaignSettings = ({
  campaignData,
  setCampaignData,
}: CampaignSettingsProps) => {
  const [emailConfigData, setEmailConfigData] = useState<any[]>([]);
  const reloadData = async () => {
    const response: AxiosResponse = await getAllEmailConfig({
      page: 0,
      limit: 100,
    });

    if (!response.error) {
      await setEmailConfigData(response.data);
    }
  };

  useEffect(() => {
    reloadData();
  }, []);

  return (
    <div className="campaign-settings-area">
      <div className="campaign-settings-area--title">Campaign Settings</div>
      <div className="campaign-settings-area--content">
        <TextField
          name="campaign-name"
          disabled={campaignData.id !== "new"}
          value={campaignData.name}
          onChange={(event) =>
            setCampaignData({
              ...campaignData,
              name: event.target.value,
            })
          }
          label="Campaign name"
          variant="standard"
          className="campaign-settings-area--input"
        />

        <Autocomplete
          multiple
          disabled={campaignData.id !== "new"}
          id="tags-filled"
          className="campaign-settings-area--input"
          options={emailConfigData}
          getOptionLabel={(option) => option.smtp.host}
          defaultValue={campaignData.accounts}
          freeSolo
          renderTags={(_, getTagProps) => {
            return campaignData.accounts.map((accountId, index) => {
              const emailConfig = emailConfigData.find(
                (email) => email.id === accountId
              );
              return (
                <Chip
                  variant="outlined"
                  label={emailConfig?.smtp?.host}
                  {...getTagProps({ index })}
                />
              );
            });
          }}
          onChange={(_, value) => {
            setCampaignData({
              ...campaignData,
              accounts: value?.map((item) => item.id),
            });
          }}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label="Email Accounts" />
          )}
        />
      </div>
    </div>
  );
};

export default CampaignSettings;
