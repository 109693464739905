import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import {
  ComponentItem,
  componentList,
  componentRendererMap,
} from "../ComponentList/ComponentListData";
import "./HtmlBuilder.scss";
import { VIEWS } from "./HtmlSettings/HtmlSettings";

export interface HtmlBuilderProps {
  htmlComponents: ComponentItem[];
  selectedCompoonentId?: string;
  globalStyleComponent?: ComponentItem;
  openAttributeForm: (componentSelected: ComponentItem) => void;
  previewMode: VIEWS;
  exportRef: any;
}

export const HTML_BUILDER_AREA = "template-builder-area";

const HtmlBuilder = ({
  htmlComponents,
  openAttributeForm,
  selectedCompoonentId,
  globalStyleComponent,
  previewMode,
  exportRef,
}: HtmlBuilderProps) => {
  return (
    <div className="template-area--component-builder" ref={exportRef}>
      <Droppable droppableId={HTML_BUILDER_AREA}>
        {(provided, snapshot) => (
          <div
            id="template-area"
            className={`template-area--component-builder ${
              previewMode === VIEWS.MOBILE && "mobile-area"
            }`}
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              ...globalStyleComponent?.props.style,
              lineHeight: `${globalStyleComponent?.props.style}px`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "720px 100vh",
              backgroundImage: `url(${globalStyleComponent?.props?.properties?.backgroundImage})`,
            }}
          >
            {htmlComponents.map((component, index) => {
              const { id, name, props } = component;
              const ComponentRender: () => JSX.Element | null =
                componentRendererMap.get(name);

              const componentProps = { ...props, id };
              return (
                <div
                  key={id}
                  className={`template-area--component-item ${
                    !snapshot.isDraggingOver &&
                    selectedCompoonentId === component.id &&
                    "active"
                  } `}
                  onClick={(event) => {
                    event.stopPropagation();
                    openAttributeForm(component);
                  }}
                >
                  <Draggable
                    key={`${id}`}
                    draggableId={`${id}`}
                    index={index}
                    disableInteractiveElementBlocking={true}
                  >
                    {(provided) => (
                      <>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {ComponentRender && (
                            <ComponentRender
                              {...componentProps}
                              previewMode={previewMode}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </Draggable>
                </div>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default HtmlBuilder;
