import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import EmailBookForm from "./EmailBookForm";
import { generateContactFromString, validateContacts } from "./utils";
import { addBulkContact, addContact } from "./service";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
type EmailBookModalProps = {
  isOpen: boolean;
  isImport: boolean;
  contactDataToAdd: any[];
  setContactDataToAdd: (value: any[]) => void;
  handleClose: () => void;
  reloadData: () => void;
  reloadGroupData: () => void;
  showNotification: (message: string, isError: boolean) => void;
  groupList: any[];
};
const EmailBookModal = ({
  isOpen,
  handleClose,
  reloadData,
  reloadGroupData,
  isImport,
  contactDataToAdd,
  setContactDataToAdd,
  showNotification,
  groupList,
}: EmailBookModalProps) => {
  const [selectedGroup, setSelectedGroup] = useState<any>([]);
  const onValueChange = (value: string) => {
    const contactDataArray = generateContactFromString(value);
    setContactDataToAdd(contactDataArray);
  };

  const onClose = () => {
    setContactDataToAdd([]);
    handleClose();
  };

  const onSaveChanges = async () => {
    const isvalidData = validateContacts(contactDataToAdd);

    if (isvalidData) {
      try {
        const response = await addBulkContact({
          data: contactDataToAdd.map((contact: any) => ({
            first_name: contact.firstName,
            last_name: contact.lastName,
            email: contact.email,
            phone: `+91${contact.phone}`,
            tags: "",
            groups:
              selectedGroup?.map((group: any) => group._id).join(",") || "",
          })),
        });

        if (!response.error) {
          reloadData();
          onClose();
          showNotification("Contacts added successfully.", false);
        } else {
          showNotification(response.error, true);
        }
      } catch (error: any) {
        showNotification(error.message, true);
      }
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="email-contact-add-modal"
        open={isOpen}
        className="email-config-add-modal"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
          Add Contact
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div className="email-config-add-modal--content">
            <EmailBookForm
              selectedGroup={selectedGroup}
              contactData={contactDataToAdd}
              onValueChange={onValueChange}
              isImport={isImport}
              groupList={groupList}
              reloadGroupData={reloadGroupData}
              onGroupChange={(value: any) => setSelectedGroup([value])}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onSaveChanges}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default EmailBookModal;
