import "./AttributeForm.scss";
import TextInput from "./Utils/TextInput";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import "./SocialMediaItemsForm.scss";
import { v4 as uuid } from "uuid";
import { SOCIAL_MEDIA_ITEM } from "../../ComponentList/ComponentListData";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { TextField } from "@mui/material";
import styled from "styled-components";

interface SocialMediaItemsFormProps {
  items: any[];
  onChange: (items: any) => void;
  addSocialMediaItem: (item: any) => void;
  deleteSocialMediaItem: (item: any) => void;
  rearrangeSocialMediaItem: (index1: number, index2: number) => void;
}
export const SOCIAL_MEDIA = "social-media";

const SocialMediaItemsForm = ({
  items,
  onChange,
  addSocialMediaItem,
  deleteSocialMediaItem,
  rearrangeSocialMediaItem,
}: SocialMediaItemsFormProps) => {
  const handleOnDragEnd = (result: any) => {
    console.log(result);
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    rearrangeSocialMediaItem(sourceIndex, destinationIndex);
  };
  return (
    <div className="attribute-form--field">
      <div className="socail-media-icon-list">
        <img
          className="social-media-icon"
          src="https://cdn.tools.unlayer.com/social/icons/circle/facebook.png"
          alt="Facebook"
          title="Facebook"
          onClick={() =>
            addSocialMediaItem({
              ...SOCIAL_MEDIA_ITEM,
              id: uuid(),
              name: "Facebook",
              iconUrl:
                "https://cdn.tools.unlayer.com/social/icons/circle/facebook.png",
            })
          }
        />
        <img
          className="social-media-icon"
          src="https://cdn.tools.unlayer.com/social/icons/circle/twitter.png"
          alt="Twitter"
          title="Twitter"
          onClick={() =>
            addSocialMediaItem({
              ...SOCIAL_MEDIA_ITEM,
              id: uuid(),
              name: "Twitter",
              iconUrl:
                "https://cdn.tools.unlayer.com/social/icons/circle/twitter.png",
            })
          }
        />
        <img
          className="social-media-icon"
          src="https://cdn.tools.unlayer.com/social/icons/circle/linkedin.png"
          alt="LinkedIn"
          title="LinkedIn"
          onClick={() =>
            addSocialMediaItem({
              ...SOCIAL_MEDIA_ITEM,
              id: uuid(),
              name: "LinkedIn",
              iconUrl:
                "https://cdn.tools.unlayer.com/social/icons/circle/linkedin.png",
            })
          }
        />
        <img
          className="social-media-icon"
          src="https://cdn.tools.unlayer.com/social/icons/circle/instagram.png"
          alt="Instagram"
          title="Instagram"
          onClick={() =>
            addSocialMediaItem({
              ...SOCIAL_MEDIA_ITEM,
              id: uuid(),
              name: "Instagram",
              iconUrl:
                "https://cdn.tools.unlayer.com/social/icons/circle/instagram.png",
            })
          }
        />
        <img
          className="social-media-icon"
          src="https://cdn.tools.unlayer.com/social/icons/circle/pinterest.png"
          alt="Pinterest"
          title="Pinterest"
          onClick={() =>
            addSocialMediaItem({
              ...SOCIAL_MEDIA_ITEM,
              id: uuid(),
              name: "Pinterest",
              iconUrl:
                "https://cdn.tools.unlayer.com/social/icons/circle/pinterest.png",
            })
          }
        />
        <img
          className="social-media-icon"
          src="https://cdn.tools.unlayer.com/social/icons/circle/vimeo.png"
          alt="Vimeo"
          title="Vimeo"
          onClick={() =>
            addSocialMediaItem({
              ...SOCIAL_MEDIA_ITEM,
              id: uuid(),
              name: "Vimeo",
              iconUrl:
                "https://cdn.tools.unlayer.com/social/icons/circle/vimeo.png",
            })
          }
        />
        <img
          className="social-media-icon"
          src="https://cdn.tools.unlayer.com/social/icons/circle/youtube.png"
          alt="YouTube"
          title="YouTube"
          onClick={() =>
            addSocialMediaItem({
              ...SOCIAL_MEDIA_ITEM,
              id: uuid(),
              name: "YouTube",
              iconUrl:
                "https://cdn.tools.unlayer.com/social/icons/circle/youtube.png",
            })
          }
        />
        <img
          className="social-media-icon"
          src="https://cdn.tools.unlayer.com/social/icons/circle/snapchat.png"
          alt="Snapchat"
          title="Snapchat"
          onClick={() =>
            addSocialMediaItem({
              ...SOCIAL_MEDIA_ITEM,
              id: uuid(),
              name: "Snapchat",
              iconUrl:
                "https://cdn.tools.unlayer.com/social/icons/circle/snapchat.png",
            })
          }
        />
        <img
          className="social-media-icon"
          src="https://cdn.tools.unlayer.com/social/icons/circle/whatsapp.png"
          alt="WhatsApp"
          title="WhatsApp"
          onClick={() =>
            addSocialMediaItem({
              ...SOCIAL_MEDIA_ITEM,
              id: uuid(),
              name: "WhatsApp",
              iconUrl:
                "https://cdn.tools.unlayer.com/social/icons/circle/whatsapp.png",
            })
          }
        />

        <img
          className="social-media-icon"
          src="https://cdn.tools.unlayer.com/social/icons/circle/telegram.png"
          alt="Telegram"
          title="Telegram"
          onClick={() =>
            addSocialMediaItem({
              ...SOCIAL_MEDIA_ITEM,
              id: uuid(),
              name: "Telegram",
              iconUrl:
                "https://cdn.tools.unlayer.com/social/icons/circle/telegram.png",
            })
          }
        />
      </div>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId={SOCIAL_MEDIA}>
          {(provided) => (
            <div
              className="social-media-item-list"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items.map((item, index) => {
                return (
                  <Draggable
                    key={item.id}
                    draggableId={`social-media-${item.id}`}
                    index={index}
                  >
                    {(provided) => (
                      <>
                        <div
                          key={item.id}
                          className="social-media-item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <DragIndicatorIcon style={{ width: "20px" }} />
                          <img
                            className="social-media-icon"
                            src={item.iconUrl}
                            alt={item.name}
                            title={item.name}
                          />
                          <TextField
                            placeholder={`${item.name} link`}
                            variant="outlined"
                            value={item.url}
                            className="url-input"
                            onChange={(event: any) =>
                              onChange({ ...item, url: event.target.value })
                            }
                          />

                          <DeleteForeverIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteSocialMediaItem(item)}
                          />
                        </div>
                      </>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default SocialMediaItemsForm;
