import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCQOYD9nNS2ysDBk8E5wSY8bROrQxs2kkU",
  authDomain: "digi-bulk-marketing.firebaseapp.com",
  databaseURL:
    "https://digi-bulk-marketing-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "digi-bulk-marketing",
  storageBucket: "digi-bulk-marketing.appspot.com",
  messagingSenderId: "281798549977",
  appId: "1:281798549977:web:a5b5d3a0d91c85be44f9c8",
  measurementId: "G-ZECCHBSWK8",
};

firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
export { auth, firebase };
