import { ComponentItem } from "../../ComponentList/ComponentListData";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import "./Navigation.scss";
import { Draggable, Droppable } from "react-beautiful-dnd";

export interface NavigationComponentProps {
  htmlComponents: ComponentItem[];
}

export const NAVIGATION_AREA = "navigation-area";

const NavigationComponent = ({ htmlComponents }: NavigationComponentProps) => {
  return (
    <Droppable droppableId={NAVIGATION_AREA}>
      {(provided) => (
        <div
          className="navigation-list"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {htmlComponents.map(({ id, name }, index) => {
            return (
              <Draggable
                key={id}
                draggableId={`navigation-item-${id}`}
                index={index}
              >
                {(provided) => (
                  <div
                    key={id}
                    className="navigation-item"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <DragIndicatorIcon style={{ width: "20px" }} />
                    <div>{name}</div>
                  </div>
                )}
              </Draggable>
            );
          })}
        </div>
      )}
    </Droppable>

    // <div className="navigation-list">
    //   {htmlComponents.map((component: ComponentItem) => (
    //     <div key={component.id} className="navigation-item">
    //       <DragIndicatorIcon style={{ width: "20px" }} />
    //       <div>{component.name}</div>
    //     </div>
    //   ))}
    // </div>
  );
};

export default NavigationComponent;
