import "./AttributeForm.scss";
import {
  ComponentItem,
  componentList,
  MENU_ITEM,
  NO_COLUMN_ITEM,
} from "../../ComponentList/ComponentListData";
import ColorPicker from "./Utils/ColorPicker";
import { ChangeEvent, useMemo, useState } from "react";
import NumberInput from "./Utils/NumberInput";
import TextAlignInput from "./Utils/TextAlignInput";
import FontFamilySelect from "./Utils/FontFamilySelect";
import TextInput from "./Utils/TextInput";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Editor from "react-simple-wysiwyg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import HtmlInput from "./Utils/HtmlInput";
import FlexDirectionInput from "./Utils/FlexDirectionInput";
import MenuItemsForm from "./MenuItemsForm";
import { v4 as uuid } from "uuid";
import BorderTypeSelect from "./Utils/BorderTypeSelect";
import ComponentSelect from "./Utils/ComponentSelect";
import SocialMediaItemsForm from "./SocialMediaItemsForm";
import FormField from "./FormInput/FormField";
import AccordianComponent from "./Utils/AccordianComponent";
import MediaSelectionModal from "./Utils/MediaSelectionModal";
import FlexAlignInput from "./Utils/FlexAlignInput";

interface AttributesFormBodyNewProps {
  selectedComponent: ComponentItem;
  onAttributeChange: (updatedComponent: ComponentItem) => void;
}
const AttributesFormBodyNew = ({
  selectedComponent,
  onAttributeChange,
}: AttributesFormBodyNewProps) => {
  const { props } = selectedComponent;
  const [isOpen, setOpen] = useState(false);
  const [selectedMediaPropName, setSelectedMediaPropName] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setSelectedMediaPropName("");
    setOpen(false);
  };

  const hasPadding = useMemo(
    () =>
      !isNaN(props?.style?.paddingTop) ||
      !isNaN(props?.style?.paddingRight) ||
      !isNaN(props?.style?.paddingBottom) ||
      !isNaN(props?.style?.paddingLeft),
    [props]
  );

  const hasMargin = useMemo(
    () =>
      !isNaN(props?.style?.marginTop) ||
      !isNaN(props?.style?.marginRight) ||
      !isNaN(props?.style?.marginBottom) ||
      !isNaN(props?.style?.marginLeft),
    [props]
  );

  const updateStyleInSelectedComponent = (newStyleData: any) => {
    const updatedComponent = {
      ...selectedComponent,
      props: { ...props, style: newStyleData },
    };

    onAttributeChange(updatedComponent);
  };

  const updatePropertiesInSelectedComponent = (newPropertiesData: any) => {
    const updatedComponent = {
      ...selectedComponent,
      props: { ...props, properties: newPropertiesData },
    };

    onAttributeChange(updatedComponent);
  };

  const onAttributeStyleValueChange = (newValue: any, perameter: string) => {
    const newStyleData = { ...props.style };
    newStyleData[perameter] = newValue;
    updateStyleInSelectedComponent(newStyleData);
  };

  const onAttributePropertiesValueChange = (
    newValue: any,
    perameter: string
  ) => {
    const newPropertiesData = { ...props.properties };
    newPropertiesData[perameter] = newValue;
    updatePropertiesInSelectedComponent(newPropertiesData);
  };

  const onMenuItemChange = (value: any) => {
    const itemExist = props.properties.menuItems.some(
      (item: any) => item.id === value.id
    );

    let newItems = [];
    if (itemExist) {
      newItems = props.properties.menuItems.map((item: any) =>
        item.id === value.id ? value : item
      );
    } else {
      newItems = [...props.properties.menuItems, value];
    }

    const newPropertiesData = { ...props.properties, menuItems: newItems };
    updatePropertiesInSelectedComponent(newPropertiesData);
  };

  const addMenuItem = () => {
    const newPropertiesData = {
      ...props.properties,
      menuItems: [...props.properties.menuItems, { id: uuid(), ...MENU_ITEM }],
    };
    updatePropertiesInSelectedComponent(newPropertiesData);
  };

  const deleteItem = (value: any) => {
    const newPropertiesData = {
      ...props.properties,
      menuItems: [
        ...props.properties.menuItems.filter(
          (item: any) => item.id !== value.id
        ),
      ],
    };
    updatePropertiesInSelectedComponent(newPropertiesData);
  };

  const onChangeNoOfColumns = (noOfColumns: number) => {
    let newPropertiesData;
    if (noOfColumns > props.properties.noOfColumns) {
      const newItems = [
        ...props.properties.items,
        {
          id: uuid(),
          ...NO_COLUMN_ITEM,
        },
      ];

      newPropertiesData = {
        ...props.properties,
        items: newItems,
        noOfColumns,
        columnWidths: [...props.properties.columnWidths, 50],
      };
    } else {
      const newItems = props.properties.items.slice(0, noOfColumns);
      const newColumnWidths = props.properties.columnWidths.slice(
        0,
        noOfColumns
      );

      newPropertiesData = {
        ...props.properties,
        items: newItems,
        noOfColumns,
        columnWidths: newColumnWidths,
      };
    }

    updatePropertiesInSelectedComponent(newPropertiesData);
  };

  const onColumnComponentChange = (componentId: string) => {
    const componentObj =
      componentId === "no-component"
        ? NO_COLUMN_ITEM
        : componentList.find(
            (component: ComponentItem) => component.id === componentId
          );

    if (componentObj) {
      const newProperties = {
        ...componentObj.props.properties,
        componentType: componentId,
      };
      const newStyle = {
        ...selectedComponent.props.style,
        ...componentObj.props.style,
      };
      const newSelectedComponent = {
        ...componentObj,
        id: selectedComponent.id,
        props: { properties: newProperties, style: newStyle },
      } as ComponentItem;

      onAttributeChange(newSelectedComponent);
    }
  };

  const addSocialMediaItem = (item: any) => {
    const newPropertiesData = {
      ...props.properties,
      socialItems: [...props.properties.socialItems, item],
    };
    updatePropertiesInSelectedComponent(newPropertiesData);
  };

  const onSocialMediaChange = (value: any) => {
    const newItems = props.properties.socialItems.map((item: any) =>
      item.id === value.id ? value : item
    );

    const newPropertiesData = { ...props.properties, socialItems: newItems };
    updatePropertiesInSelectedComponent(newPropertiesData);
  };

  const deleteSocialMediaItem = (value: any) => {
    const newPropertiesData = {
      ...props.properties,
      socialItems: [
        ...props.properties.socialItems.filter(
          (item: any) => item.id !== value.id
        ),
      ],
    };
    updatePropertiesInSelectedComponent(newPropertiesData);
  };

  const rearrangeSocialMediaItem = (
    sourceIndex: number,
    destinationIndex: number
  ) => {
    const newItems = [...props.properties.socialItems];
    const sourceItem = props.properties.socialItems[sourceIndex];
    newItems.splice(sourceIndex, 1);
    newItems.splice(destinationIndex, 0, sourceItem);
    const newPropertiesData = {
      ...props.properties,
      socialItems: [...newItems],
    };
    updatePropertiesInSelectedComponent(newPropertiesData);
  };

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const hasBodyProperties = useMemo(
    () =>
      props?.properties?.htmlCode !== undefined ||
      props?.properties?.menuItems !== undefined ||
      props?.properties?.socialItems !== undefined ||
      props?.properties?.text !== undefined ||
      !isNaN(props?.style?.size) ||
      props?.style?.dividerStyle !== undefined ||
      props?.properties?.src ||
      props?.properties?.altText ||
      props?.properties?.label !== undefined ||
      props?.properties?.url !== undefined ||
      props?.properties?.targetBlank !== undefined,
    [props]
  );
  return (
    <div className="attribute-form--body">
      {hasBodyProperties && (
        <Accordion
          expanded={expanded === "component-settings"}
          onChange={handleChange("component-settings")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography>Component Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {props?.properties?.htmlCode !== undefined && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">Code</div>
                <HtmlInput
                  code={props.properties?.htmlCode}
                  onChange={(newValue: string) =>
                    onAttributePropertiesValueChange(newValue, "htmlCode")
                  }
                />
              </div>
            )}
            {props?.properties?.menuItems !== undefined && (
              <MenuItemsForm
                items={props?.properties?.menuItems}
                onChange={onMenuItemChange}
                addMenuItem={addMenuItem}
                deleteItem={deleteItem}
              />
            )}
            {props?.properties?.socialItems !== undefined && (
              <SocialMediaItemsForm
                items={props?.properties?.socialItems}
                onChange={onSocialMediaChange}
                addSocialMediaItem={addSocialMediaItem}
                deleteSocialMediaItem={deleteSocialMediaItem}
                rearrangeSocialMediaItem={rearrangeSocialMediaItem}
              />
            )}
            {props?.properties?.text !== undefined && (
              <div className="attribute-form--field">
                <Editor
                  value={props.properties?.text}
                  onChange={(event: any) =>
                    onAttributePropertiesValueChange(event.target.value, "text")
                  }
                />
                {/* <TextField
              id="text-area-input"
              label="Text"
              multiline
              style={{ width: "100%" }}
              value={props.properties?.text}
              variant="standard"
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => onAttributePropertiesValueChange(event.target.value, "text")}
            /> */}
              </div>
            )}
            {!isNaN(props?.style?.size) && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">Size</div>
                <div className="attribute-form--field-input">
                  <NumberInput
                    minValue={1}
                    value={props.style.size}
                    onChange={(newValue: number) =>
                      onAttributeStyleValueChange(newValue, "size")
                    }
                  />
                </div>
              </div>
            )}
            {props?.style?.dividerStyle && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">Divider Style</div>
                <div className="attribute-form--field-input">
                  <BorderTypeSelect
                    value={props.style.dividerStyle}
                    onChange={(newValue: string) =>
                      onAttributeStyleValueChange(newValue, "dividerStyle")
                    }
                  />
                </div>
              </div>
            )}
            {/* {props?.properties?.mediaType !== undefined && (
          <div className="attribute-form--field">
            <div className="attribute-form--field-label">MediaType</div>
            <RadioGroup
              row
              value={props.properties.mediaType}
              onChange={(event: any) =>
                onAttributePropertiesValueChange(
                  event.target.value,
                  "mediaType"
                )
              }
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="image"
                control={<Radio />}
                label="Image"
              />
              <FormControlLabel value="icon" control={<Radio />} label="Icon" />
            </RadioGroup>
          </div>
        )} */}
            {props?.properties?.src !== undefined && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">
                  {props?.properties?.mediaType === "icon" ? "Icon" : "Image"}
                </div>
                <IconButton
                  className="upload-button--icon"
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={() => {
                    setSelectedMediaPropName("src");
                    handleOpen();
                  }}
                >
                  <PhotoCamera />
                </IconButton>
                <TextInput
                  label={`${
                    props?.properties?.mediaType === "icon" ? "Icon" : "Image"
                  } URL`}
                  value={props.properties?.src}
                  onChange={(newValue: string) =>
                    onAttributePropertiesValueChange(newValue, "src")
                  }
                />
              </div>
            )}
            {props?.properties?.altText !== undefined && (
              <div className="attribute-form--field">
                <TextInput
                  label="Alternate Text"
                  value={props.properties?.altText}
                  onChange={(newValue: string) =>
                    onAttributePropertiesValueChange(newValue, "altText")
                  }
                />
              </div>
            )}{" "}
            {props?.properties?.label !== undefined && (
              <div className="attribute-form--field">
                <TextInput
                  label="Label"
                  value={props.properties?.label}
                  onChange={(newValue: string) =>
                    onAttributePropertiesValueChange(newValue, "label")
                  }
                />
              </div>
            )}
            {props?.properties?.url !== undefined && (
              <div className="attribute-form--field">
                <TextInput
                  label="URL"
                  value={props.properties?.url}
                  onChange={(newValue: string) =>
                    onAttributePropertiesValueChange(newValue, "url")
                  }
                />
              </div>
            )}
            {props?.properties?.targetBlank !== undefined && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">
                  Open in new tab
                </div>
                <div className="attribute-form--field-input">
                  <Switch
                    checked={props?.properties?.targetBlank}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onAttributePropertiesValueChange(
                        event.target.checked,
                        "targetBlank"
                      )
                    }
                  />
                </div>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      )}

      {(props?.properties?.componentType ||
        !isNaN(props?.properties?.noOfColumns) ||
        props?.properties?.columnWidths?.length > 0) && (
        <Accordion
          expanded={expanded === "column-settings"}
          onChange={handleChange("column-settings")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography>Column Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {props?.properties?.componentType && (
              <FormField inputClassName={["select-input"]}>
                <ComponentSelect
                  value={props?.properties?.componentType}
                  onChange={(value) => onColumnComponentChange(value)}
                />
              </FormField>
            )}
            {!isNaN(props?.properties?.noOfColumns) && (
              <FormField label="No of Columns">
                <NumberInput
                  minValue={1}
                  maxValue={3}
                  value={props.properties.noOfColumns}
                  onChange={(newValue: number) => onChangeNoOfColumns(newValue)}
                />
              </FormField>
            )}
            {props?.properties?.columnWidths?.length > 0 &&
              props?.properties?.columnWidths.map(
                (width: number, index: number) => (
                  <div className="attribute-form--field">
                    <div className="attribute-form--field-label">
                      Column {index + 1} width
                    </div>
                    <div className="attribute-form--field-input">
                      <NumberInput
                        minValue={10}
                        maxValue={70}
                        value={width}
                        onChange={(newValue: number) => {
                          const newWidths = [...props.properties.columnWidths];
                          newWidths[index] = newValue;
                          onAttributePropertiesValueChange(
                            newWidths,
                            "columnWidths"
                          );
                        }}
                      />
                    </div>
                  </div>
                )
              )}
          </AccordionDetails>
        </Accordion>
      )}

      {(!isNaN(props?.style?.buttonWidth) ||
        props?.style?.buttonBackgroundColor ||
        !isNaN(props?.style?.buttonBorderRadius) ||
        props?.style?.buttonAlign) && (
        <Accordion
          expanded={expanded === "button-settings"}
          onChange={handleChange("button-settings")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography>Button Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {!isNaN(props?.style?.buttonWidth) && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">Width</div>
                <div className="attribute-form--field-input">
                  <NumberInput
                    minValue={1}
                    maxValue={100}
                    value={props.style.buttonWidth}
                    onChange={(newValue: number) =>
                      onAttributeStyleValueChange(newValue, "buttonWidth")
                    }
                  />
                </div>
              </div>
            )}
            {props?.style?.buttonBackgroundColor && (
              <FormField label="Background Color">
                <ColorPicker
                  value={props.style.buttonBackgroundColor || "#ffffff"}
                  onChange={(newValue: string) =>
                    onAttributeStyleValueChange(
                      newValue,
                      "buttonBackgroundColor"
                    )
                  }
                />
              </FormField>
            )}
            {!isNaN(props?.style?.buttonBorderRadius) && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">Border Radius</div>
                <div className="attribute-form--field-input">
                  <NumberInput
                    minValue={0}
                    value={props.style.buttonBorderRadius}
                    onChange={(newValue: number) =>
                      onAttributeStyleValueChange(
                        newValue,
                        "buttonBorderRadius"
                      )
                    }
                  />
                </div>
              </div>
            )}
            {props?.style?.buttonAlign && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">Button Align</div>
                <div className="attribute-form--field-input">
                  <TextAlignInput
                    value={props.style.buttonAlign}
                    onChange={(newValue: string) =>
                      onAttributeStyleValueChange(newValue, "buttonAlign")
                    }
                  />
                </div>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      )}

      {(props?.style?.color ||
        props?.style?.textAlign ||
        props?.style?.fontFamily ||
        !isNaN(props?.style?.lineHeight) ||
        props?.style?.fontSize) && (
        <Accordion
          expanded={expanded === "text-settings"}
          onChange={handleChange("text-settings")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>Text Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {props?.style?.color && (
              <FormField label="Color">
                <ColorPicker
                  value={props.style.color || "#000000"}
                  onChange={(newValue: string) =>
                    onAttributeStyleValueChange(newValue, "color")
                  }
                />
              </FormField>
            )}
            {props?.style?.textAlign && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">Text Align</div>
                <div className="attribute-form--field-input">
                  <TextAlignInput
                    value={props.style.textAlign}
                    onChange={(newValue: string) =>
                      onAttributeStyleValueChange(newValue, "textAlign")
                    }
                  />
                </div>
              </div>
            )}
            {props?.style?.fontFamily && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">Font Family</div>
                <div className="attribute-form--field-input">
                  <FontFamilySelect
                    value={props.style.fontFamily}
                    onChange={(newValue: string) =>
                      onAttributeStyleValueChange(newValue, "fontFamily")
                    }
                  />
                </div>
              </div>
            )}
            {!isNaN(props?.style?.lineHeight) && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">Line Height</div>
                <div className="attribute-form--field-input">
                  <NumberInput
                    minValue={20}
                    value={props.style.lineHeight}
                    onChange={(newValue: number) =>
                      onAttributeStyleValueChange(newValue, "lineHeight")
                    }
                  />
                </div>
              </div>
            )}
            {props?.style?.fontSize && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">Font Size</div>
                <div className="attribute-form--field-input">
                  <NumberInput
                    value={props.style.fontSize}
                    onChange={(newValue: number) =>
                      onAttributeStyleValueChange(newValue, "fontSize")
                    }
                  />
                </div>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      )}

      {(props?.properties?.backgroundImage ||
        props?.style?.backgroundColor) && (
        <Accordion
          expanded={expanded === "background"}
          onChange={handleChange("background")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography>Background</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {props?.properties?.backgroundImage !== undefined && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">
                  Background Image
                </div>
                <IconButton
                  className="upload-button--icon"
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={() => {
                    setSelectedMediaPropName("backgroundImage");
                    handleOpen();
                  }}
                >
                  <PhotoCamera />
                </IconButton>
                <TextInput
                  label="Image URL"
                  value={props.properties?.backgroundImage}
                  onChange={(newValue: string) =>
                    onAttributePropertiesValueChange(
                      newValue,
                      "backgroundImage"
                    )
                  }
                />
              </div>
            )}
            {props?.style?.backgroundColor && (
              <FormField label="Background Color">
                <ColorPicker
                  value={props.style.backgroundColor || "#000000"}
                  onChange={(newValue: string) =>
                    onAttributeStyleValueChange(newValue, "backgroundColor")
                  }
                />
              </FormField>
            )}
          </AccordionDetails>
        </Accordion>
      )}

      {(props?.style?.borderColor ||
        !isNaN(props?.style?.borderSize) ||
        props?.style?.borderType ||
        !isNaN(props?.style?.borderRadius)) && (
        <Accordion
          expanded={expanded === "border"}
          onChange={handleChange("border")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography>Border</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {props?.style?.borderColor && (
              <FormField label="Border Color">
                <ColorPicker
                  value={props.style.borderColor || "#000000"}
                  onChange={(newValue: string) =>
                    onAttributeStyleValueChange(newValue, "borderColor")
                  }
                />
              </FormField>
            )}
            {!isNaN(props?.style?.borderSize) && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">Border Size</div>
                <div className="attribute-form--field-input">
                  <NumberInput
                    minValue={0}
                    maxValue={20}
                    value={props.style.borderSize}
                    onChange={(newValue: number) =>
                      onAttributeStyleValueChange(newValue, "borderSize")
                    }
                  />
                </div>
              </div>
            )}
            {props?.style?.borderType && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">Border Type</div>
                <div className="attribute-form--field-input">
                  <BorderTypeSelect
                    value={props.style.borderType}
                    onChange={(newValue: string) =>
                      onAttributeStyleValueChange(newValue, "borderType")
                    }
                  />
                </div>
              </div>
            )}
            {!isNaN(props?.style?.borderRadius) && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">Border Radius</div>
                <div className="attribute-form--field-input">
                  <NumberInput
                    minValue={0}
                    value={props.style.borderRadius}
                    onChange={(newValue: number) =>
                      onAttributeStyleValueChange(newValue, "borderRadius")
                    }
                  />
                </div>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {(!isNaN(props?.style?.width) ||
        !isNaN(props?.style?.zIndex) ||
        props?.style?.flexAlign) && (
        <Accordion
          expanded={expanded === "container"}
          onChange={handleChange("container")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography>Block Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {!isNaN(props?.style?.width) && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">Width</div>
                <div className="attribute-form--field-input">
                  <NumberInput
                    minValue={1}
                    maxValue={100}
                    value={props.style.width}
                    onChange={(newValue: number) =>
                      onAttributeStyleValueChange(newValue, "width")
                    }
                  />
                </div>
              </div>
            )}
            {!isNaN(props?.style?.zIndex) && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">Z Index</div>
                <div className="attribute-form--field-input">
                  <NumberInput
                    value={props.style.zIndex}
                    onChange={(newValue: number) => {
                      console.log("newValue: ", newValue);
                      onAttributeStyleValueChange(newValue, "zIndex");
                    }}
                  />
                </div>
              </div>
            )}
            {props?.style?.flexAlign !== undefined && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">Align Items</div>
                <div className="attribute-form--field-input">
                  <FlexAlignInput
                    value={props.style.flexAlign}
                    onChange={(newValue: string) =>
                      onAttributeStyleValueChange(newValue, "flexAlign")
                    }
                  />
                </div>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {(props?.style?.flexDirection || !isNaN(props?.style?.gap)) && (
        <Accordion
          expanded={expanded === "flex-setting"}
          onChange={handleChange("flex-setting")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography>Flex Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {props?.style?.flexDirection !== undefined && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">Layout</div>
                <div className="attribute-form--field-input">
                  <FlexDirectionInput
                    value={props.style.flexDirection}
                    onChange={(newValue: string) =>
                      onAttributeStyleValueChange(newValue, "flexDirection")
                    }
                  />
                </div>
              </div>
            )}
            {!isNaN(props?.style?.gap) && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">
                  Gap between items
                </div>
                <div className="attribute-form--field-input">
                  <NumberInput
                    minValue={0}
                    value={props.style.gap}
                    onChange={(newValue: number) =>
                      onAttributeStyleValueChange(newValue, "gap")
                    }
                  />
                </div>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {props?.style?.iconSpacing && (
        <Accordion
          expanded={expanded === "icon-setting"}
          onChange={handleChange("icon-setting")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography>Icon Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {!isNaN(props?.style?.iconSpacing) && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label">Icon Spacing</div>
                <div className="attribute-form--field-input">
                  <NumberInput
                    minValue={5}
                    value={props.style.iconSpacing}
                    onChange={(newValue: number) =>
                      onAttributeStyleValueChange(newValue, "iconSpacing")
                    }
                  />
                </div>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {(hasPadding || hasMargin) && (
        <Accordion
          expanded={expanded === "spacing"}
          onChange={handleChange("spacing")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography>Spacing</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {hasPadding && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label full-width">
                  Padding
                </div>
                <div className="attribute-form--field-multi-input">
                  <NumberInput
                    minValue={0}
                    label="Top"
                    value={props.style.paddingTop}
                    onChange={(newValue: number) =>
                      onAttributeStyleValueChange(newValue, "paddingTop")
                    }
                  />
                  <NumberInput
                    minValue={0}
                    label="Right"
                    value={props.style.paddingRight}
                    onChange={(newValue: number) =>
                      onAttributeStyleValueChange(newValue, "paddingRight")
                    }
                  />
                  <NumberInput
                    minValue={0}
                    label="Bottom"
                    value={props.style.paddingBottom}
                    onChange={(newValue: number) =>
                      onAttributeStyleValueChange(newValue, "paddingBottom")
                    }
                  />
                  <NumberInput
                    minValue={0}
                    label="Left"
                    value={props.style.paddingLeft}
                    onChange={(newValue: number) =>
                      onAttributeStyleValueChange(newValue, "paddingLeft")
                    }
                  />
                </div>
              </div>
            )}
            {hasMargin && (
              <div className="attribute-form--field">
                <div className="attribute-form--field-label full-width">
                  Margin
                </div>
                <div className="attribute-form--field-multi-input">
                  <NumberInput
                    label="Top"
                    value={props.style.marginTop}
                    onChange={(newValue: number) =>
                      onAttributeStyleValueChange(newValue, "marginTop")
                    }
                  />
                  <NumberInput
                    label="Right"
                    value={props.style.marginRight}
                    onChange={(newValue: number) =>
                      onAttributeStyleValueChange(newValue, "marginRight")
                    }
                  />
                  <NumberInput
                    label="Bottom"
                    value={props.style.marginBottom}
                    onChange={(newValue: number) =>
                      onAttributeStyleValueChange(newValue, "marginBottom")
                    }
                  />
                  <NumberInput
                    label="Left"
                    value={props.style.marginLeft}
                    onChange={(newValue: number) =>
                      onAttributeStyleValueChange(newValue, "marginLeft")
                    }
                  />
                </div>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      )}

      <MediaSelectionModal
        isOpen={isOpen}
        handleClose={handleClose}
        mediaType={props?.properties?.mediaType}
        selectImage={(imageUrl: string) => {
          onAttributePropertiesValueChange(imageUrl, selectedMediaPropName);
          setSelectedMediaPropName("");
        }}
      />
    </div>
  );
};

export default AttributesFormBodyNew;
