import React from "react";
import { ObjectType } from "typescript";

const SocialShareComponent = ({ properties, style }: any) => {
  const TextDivStyle = {
    paddingTop: `${style.paddingTop}px`,
    paddingRight: `${style.paddingRight}px`,
    paddingBottom: `${style.paddingBottom}px`,
    paddingLeft: `${style.paddingLeft}px`,
    marginTop: `${style.marginTop}px`,
    marginRight: `${style.marginRight}px`,
    marginBottom: `${style.marginBottom}px`,
    marginLeft: `${style.marginLeft}px`,
    textAlign: style.textAlign,
    display: "flex",
    justifyContent: style.textAlign,
    gap: `${style.iconSpacing}px`,
    flexWrap: "wrap" as any,
    backgroundColor: style.backgroundColor,
    border: `${style.borderSize}px ${style.borderType} ${style.borderColor}`,
    zIndex: style.zIndex,
  };

  const StyledLinkStyle = {
    display: "inline-block",
    width: "32px",
    height: "32px",
    cursor: "pointer",
  };

  const shareLinkes: any = {
    Facebook: "https://www.facebook.com/sharer/sharer.php?u=",
    LinkedIn: "https://www.linkedin.com/sharing/share-offsite/?url=",
    Twitter: "https://twitter.com/intent/tweet?url=",
  };

  const StyledNoItem = {
    textAlign: "center" as "center",
    width: "100%",
  };

  const defaultProps = {
    target: "_blank",
  };
  return (
    <div style={{ ...TextDivStyle, position: "relative" }}>
      {properties?.socialItems?.length > 0 ? (
        properties?.socialItems.map((item: any) => (
          <span
            style={{ ...StyledLinkStyle }}
            {...defaultProps}
            onClick={() =>
              window.open(
                `${shareLinkes[item.name]}${item.url}/`,
                `${item.name}`,
                "width=600,height=400"
              )
            }
          >
            <img src={item.iconUrl} alt={item.name} width="32" height="32" />
          </span>
        ))
      ) : (
        <div style={{ ...StyledNoItem }}>Add Social Media Share </div>
      )}
    </div>
  );
};

export default React.memo(SocialShareComponent);
