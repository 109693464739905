import { Alert, Button, Snackbar, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import "./Settings.scss";
import { AxiosResponse, getSettings, saveSettings } from "./service";
import { State } from "../Login/Login";
export type SettingsData = {
  switchAfter: Number;
  minDelay: Number;
  maxDelay: Number;
  emailAmount: Number;
  sleepDuration: Number;
};
const Settings = () => {
  const [settings, setSettings] = useState<any>({
    switchAfter: 0,
    minDelay: 0,
    maxDelay: 10,
    emailAmount: 0,
    sleepDuration: 0,
  });
  const [notificationState, setNotificationState] = useState<State>({
    open: false,
    message: "",
    isError: false,
    vertical: "top",
    horizontal: "center",
    severity: "success",
  });

  useEffect(() => {
    reloadData();
  }, []);

  const reloadData = async () => {
    const response: AxiosResponse = await getSettings();

    if (!response.error) {
      await setSettings({
        switchAfter: response.data[0].switchAccount,
        minDelay: response.data[0].delayMin,
        maxDelay: response.data[0].delayMax,
        emailAmount: response.data[0].sleepAfter,
        sleepDuration: response.data[0].sleepFor,
      });
    }
  };

  const showNotification = (message: string, isError: boolean) => {
    setNotificationState({
      ...notificationState,
      open: true,
      message,
      isError,
      severity: isError ? "error" : "success",
    });
  };

  const closeNotification = () => {
    setNotificationState({
      ...notificationState,
      open: false,
      message: "",
      isError: false,
    });
  };

  const handleSettingsSave = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    let response: any;
    response = await saveSettings({
      switch_account: settings.switchAfter,
      delay_min: settings.minDelay,
      delay_max: settings.maxDelay,
      sleep: true,
      sleep_after: settings.emailAmount,
      sleep_for: settings.sleepDuration,
    });

    if (!response.error) {
      reloadData();
      showNotification("Settings saved successfully.", false);
    } else {
      showNotification(response.error, true);
    }
  };

  return (
    <div className="settings-area">
      <div className="settings-area--title">Settings</div>
      <form onSubmit={handleSettingsSave}>
        <div className="settings-area--form">
          <div className="settings-area--field">
            <div className="settings-area--field-input">
              <TextField
                id="text-area-input"
                label="Switch Account after"
                style={{ width: "100%" }}
                variant="standard"
                value={settings.switchAfter}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setSettings({
                    ...settings,
                    switchAfter: Number.parseInt(event.target.value || "0"),
                  })
                }
              />
            </div>
          </div>
          <div className="settings-area--field">
            <div className="settings-area--field-input">
              <div className="settings-area--field-label">Delay Between: </div>
              <div className="settings-area--field-input-box">
                <TextField
                  label="Min"
                  value={settings.minDelay}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  variant="standard"
                  style={{ width: "50%" }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setSettings({
                      ...settings,
                      minDelay: Number.parseInt(event.target.value || "0"),
                    })
                  }
                />
                <span className=""></span>
                <TextField
                  label="Max"
                  value={settings.maxDelay}
                  variant="standard"
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  style={{ width: "50%" }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setSettings({
                      ...settings,
                      maxDelay: Number.parseInt(event.target.value || "0"),
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="settings-area--field">
            <div className="settings-area--field-input">
              <div className="settings-area--field-label">Sleep Mode: </div>
              <div className="settings-area--field-input-box">
                <TextField
                  label="Email Amount"
                  value={settings.emailAmount}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  variant="standard"
                  style={{ width: "100%" }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setSettings({
                      ...settings,
                      emailAmount: Number.parseInt(event.target.value || "0"),
                    })
                  }
                />
              </div>
              <div className="settings-area--field-input-box">
                <TextField
                  label="Duration"
                  value={settings.sleepDuration}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  variant="standard"
                  style={{ width: "100%" }}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setSettings({
                      ...settings,
                      sleepDuration: Number.parseInt(event.target.value || "0"),
                    })
                  }
                />
              </div>
              <div className="settings-area--submit-button">
                <Button variant="outlined" type="submit">
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Snackbar
        anchorOrigin={{
          vertical: notificationState.vertical,
          horizontal: notificationState.horizontal,
        }}
        open={notificationState.open}
        onClose={closeNotification}
        autoHideDuration={6000}
      >
        <Alert
          onClose={closeNotification}
          severity={notificationState.severity}
          sx={{ width: "100%" }}
        >
          {notificationState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Settings;
