import { Logout } from "@mui/icons-material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import { ListItemIcon, Menu, MenuItem } from "@mui/material";

const ProfileMenu = ({ anchorElUser, handleCloseUserMenu, userData }: any) => {
  return (
    <Menu
      className="user-menu"
      sx={{ mt: "50px" }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      <MenuItem disabled className="user-name">
        Welcome {userData?.name || userData?.phone}
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleCloseUserMenu("user-management");
        }}
      >
        <ListItemIcon>
          <AccountCircleOutlinedIcon fontSize="small" />
        </ListItemIcon>
        My account
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleCloseUserMenu("smtp-config");
        }}
      >
        <ListItemIcon>
          <BuildOutlinedIcon fontSize="small" />
        </ListItemIcon>
        SMTP Configuration
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleCloseUserMenu("logout");
        }}
      >
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );
};

export default ProfileMenu;
