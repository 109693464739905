import { componentRendererMap } from "../../ComponentList/ComponentListData";

const ColumnsComponent = ({ style, properties }: any) => {
  const TextDivStyle = {
    paddingTop: `${style.paddingTop}px`,
    paddingRight: `${style.paddingRight}px`,
    paddingBottom: `${style.paddingBottom}px`,
    paddingLeft: `${style.paddingLeft}px`,
    marginTop: `${style.marginTop}px`,
    marginRight: `${style.marginRight}px`,
    marginBottom: `${style.marginBottom}px`,
    marginLeft: `${style.marginLeft}px`,
    color: style.color,
    lineHeight: `${style.lineHeight}px`,
    textAlign: style.textAlign,
    fontSize: `${style.fontSize}px`,
    fontWeight: style.fontWeight,
    fontFamily: style.fontFamily,
    minHeight: "50px",
    display: "flex",
    gap: `${style.gap}px`,
    border: `${style.borderSize}px ${style.borderType} ${style.borderColor}`,
    backgroundColor: style.backgroundColor,
    zIndex: style.zIndex,
  };

  const generateColumnDivStyle = (style: any) => {
    return {
      paddingTop: `${style.paddingTop}px`,
      paddingRight: `${style.paddingRight}px`,
      paddingBottom: `${style.paddingBottom}px`,
      paddingLeft: `${style.paddingLeft}px`,
      marginTop: `${style.marginTop}px`,
      marginRight: `${style.marginRight}px`,
      marginBottom: `${style.marginBottom}px`,
      marginLeft: `${style.marginLeft}px`,
      color: style.color,
      width: `50%`,
    };
  };

  const { columnWidths, items } = properties;

  return (
    <div style={{ ...TextDivStyle, position: "relative" }}>
      {items.map((item: any, index: number) => {
        const { id, name, props } = item;
        const ComponentRender: () => JSX.Element | null =
          componentRendererMap.get(name);

        const componentProps = { ...props, id };
        return (
          <div
            key={id}
            style={{
              ...generateColumnDivStyle(props.style),
              width: `${columnWidths[index]}%`,
            }}
          >
            {ComponentRender ? (
              <ComponentRender {...componentProps} />
            ) : (
              <>Add Component</>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ColumnsComponent;
