import "./AttributeForm.scss";
import { ComponentItem } from "../../ComponentList/ComponentListData";
import AttributesFormHeader from "./AttributesFormHeader";
import AttributesFormBody from "./AttributesFormBody";
import ColumnsAttributeFormBody from "./ColumnsAttributeFormBody";
import AttributesFormBodyNew from "./AttributesFormBodyNEW";

interface AttributeFormProps {
  selectedComponent: ComponentItem;
  isOpen: boolean;
  closeForm: () => void;
  deleteComponent: (selectedComponent: ComponentItem) => void;
  onAttributeChange: (updatedComponent: ComponentItem) => void;
}

const renderSimpleForm = (
  selectedComponent: ComponentItem,
  onAttributeChange: (updatedComponent: ComponentItem) => void
) => {
  return (
    <AttributesFormBodyNew
      selectedComponent={selectedComponent}
      onAttributeChange={onAttributeChange}
    />
  );
};

const renderColumnsForm = (
  selectedComponent: ComponentItem,
  onAttributeChange: (updatedComponent: ComponentItem) => void
) => {
  return (
    <ColumnsAttributeFormBody
      selectedComponent={selectedComponent}
      onAttributeChange={onAttributeChange}
    />
  );
};

const AttributesForm = ({
  selectedComponent,
  isOpen,
  closeForm,
  deleteComponent,
  onAttributeChange,
}: AttributeFormProps) => {
  return (
    <div className={`attribute-form ${isOpen ? "slide-in" : "slide-out"}`}>
      <AttributesFormHeader
        selectedComponent={selectedComponent}
        closeForm={closeForm}
        deleteComponent={deleteComponent}
      />
      {selectedComponent?.props?.properties?.noOfColumns
        ? renderColumnsForm(selectedComponent, onAttributeChange)
        : renderSimpleForm(selectedComponent, onAttributeChange)}
    </div>
  );
};

export default AttributesForm;
