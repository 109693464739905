import React, { useEffect, useState } from "react";
import { firebase, auth } from "./firebase";
import TextField from "@mui/material/TextField";
import "./Login.scss";
import EditIcon from "@mui/icons-material/Edit";
import { validatePhoneNumber } from "../shared/utils";
import { axiosPost } from "../shared/axiosService";
import LoadingButton from "@mui/lab/LoadingButton";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import {
  Alert,
  Button,
  IconButton,
  Snackbar,
  SnackbarOrigin,
} from "@mui/material";

export interface State extends SnackbarOrigin {
  open: boolean;
  message: string;
  isError: boolean;
  severity?: any;
}

export default function Login() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [show, setshow] = useState(false);
  const [final, setfinal] = useState<any>("");
  const [seconds, setSeconds] = useState(30);
  const [notificationState, setNotificationState] = useState<State>({
    open: false,
    message: "",
    isError: false,
    vertical: "top",
    horizontal: "center",
    severity: "success",
  });

  const showNotification = (message: string, isError: boolean) => {
    setNotificationState({
      ...notificationState,
      open: true,
      message,
      isError,
      severity: isError ? "error" : "success",
    });
  };

  const closeNotification = () => {
    setNotificationState({
      ...notificationState,
      open: false,
      message: "",
      isError: false,
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  const sendOtp = () => {
    if (validatePhoneNumber(phoneNumber.replace("+91", ""))) {
      setshow(false);
      setLoading(true);
      const verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");
      auth
        .signInWithPhoneNumber(phoneNumber, verify)
        .then((result: any) => {
          setfinal(result);
          setLoading(false);
          setshow(true);
          setSeconds(30);
          verify.clear();
        })
        .catch((err) => {
          verify.clear();
          if (err?.message) {
            showNotification(err.message, true);
          } else {
            showNotification(err, true);
          }
          setLoading(false);
        });
    } else {
      showNotification("Please eneter valid phone number", true);
    }
  };

  const loginWithFirebaseToken = (firebaseToken: string) => {
    return axiosPost("user/login", {
      id_token: firebaseToken,
      auth_type: "phone",
    });
  };
  // Validate OTP
  const validateOtp = () => {
    if (otp === null || final === null) return;

    setLoading(true);
    final
      .confirm(otp)
      .then(async () => {
        const firebaseToken = await auth.currentUser?.getIdToken();
        if (firebaseToken) {
          const response = await loginWithFirebaseToken(firebaseToken);
          localStorage.setItem("token", response.data.token);
          const isActive = response.data.user.status === "active";
          localStorage.setItem("isUserActive", isActive.toString());
          window.location.reload();
        }
      })
      .catch((err: any) => {
        console.error("error:", err);
        showNotification("Please enter valid code.", true);
        setLoading(false);
      });
  };

  return (
    <div className="login-area">
      <div className="company-logo">
        <img
          src="/images/login-side.webp"
          alt="esender"
          className="login-logo"
        />
      </div>
      <div className="login-card">
        <div className="login-form">
          <div className="login-card-title">
            {" "}
            <div className="logo">
              <img
                src="/images/logo.png"
                alt="esender"
                className="logo-image"
              />
            </div>
            Log In
          </div>
          <div className="login-form-area">
            <div className="login-form-area--phone-input">
              <PhoneInput
                country={"in"}
                value={phoneNumber}
                onChange={(value: any) => {
                  setPhoneNumber(`+${value}`);
                }}
                disabled={show}
                enableSearch={true}
              />
              {show && (
                <IconButton
                  className="edit-phone-number"
                  aria-label="edit"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    setLoading(false);
                    setshow(false);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
            </div>

            {!show && (
              <div className="action-button">
                <LoadingButton
                  variant="contained"
                  onClick={sendOtp}
                  disabled={loading}
                  loading={loading}
                >
                  Verify
                </LoadingButton>
              </div>
            )}
            <div id="recaptcha-container"></div>

            {show && (
              <>
                <TextField
                  id="standard-basic"
                  label="OTP"
                  variant="outlined"
                  className="login-input"
                  value={otp}
                  onChange={(event: any) => setOtp(event.target.value)}
                />
                <div className="resend-text">
                  {seconds !== 0 ? (
                    `Resend code in ${seconds}s`
                  ) : (
                    <span>
                      Didn't Receive otp?{" "}
                      <Button
                        className="resend-text--button"
                        onClick={() => {
                          // setshow(false);
                          sendOtp();
                        }}
                      >
                        Resend code
                      </Button>
                    </span>
                  )}
                </div>
                <div className="action-button">
                  <LoadingButton
                    variant="contained"
                    onClick={validateOtp}
                    loading={loading}
                  >
                    Confirm OTP
                  </LoadingButton>
                </div>
              </>
            )}

            <div className="policy-text">
              By signing up, you are creating a Esender account, and you agree
              to Esender's <a href="#">Terms of Use</a> and{" "}
              <a href="#">Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: notificationState.vertical,
          horizontal: notificationState.horizontal,
        }}
        open={notificationState.open}
        onClose={closeNotification}
        autoHideDuration={6000}
      >
        <Alert
          onClose={closeNotification}
          severity={notificationState.severity}
          sx={{ width: "100%" }}
        >
          {notificationState.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
