import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import "./GroupSelect.scss";
import { addGroup, AxiosResponse } from "./service";

type GroupSelectType = {
  groupList: any[];
  values?: any[];
  reloadGroupData: () => void;
  onGroupChange?: (value: any) => void;
};

const filter = createFilterOptions<GroupsType>();
const GroupSelect = ({
  groupList,
  reloadGroupData,
  values,
  onGroupChange,
}: GroupSelectType) => {
  const [value, setValue] = React.useState<GroupsType | null>(null);
  const [open, toggleOpen] = React.useState(false);

  React.useEffect(() => {
    if (values && values.length > 0) {
      setValue(values[0]);
    }
  }, [values]);

  const handleClose = () => {
    setDialogValue({
      name: "",
      id: "testId",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    name: "",
    id: "testId",
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const response: AxiosResponse = await addGroup({ name: dialogValue.name });
    setValue({
      name: dialogValue.name,
      id: response.group_id,
    });
    reloadGroupData();
    handleClose();
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: newValue,
                id: "",
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              name: newValue.inputValue,
              id: "",
            });
          } else {
            setValue(newValue);
            onGroupChange?.(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="group-select"
        options={groupList}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label="Group" variant="standard" />
        )}
      />
      <Dialog open={open} onClose={handleClose} className="add-group-modal">
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new group</DialogTitle>
          <DialogContent sx={{ width: "100%" }}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.name}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  name: event.target.value,
                })
              }
              label="Group Name"
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default GroupSelect;

interface GroupsType {
  inputValue?: string;
  id?: string;
  name: string;
}
