import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase";

const Logout = () => {
  let navigate = useNavigate();
  useEffect(() => {
    auth.signOut();
    localStorage.clear();
    window.location.reload();
  }, [navigate]);

  return <></>;
};

export default Logout;
