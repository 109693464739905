import React, { useEffect, useState } from "react";
import GroupList from "./GroupList";
import GroupModal from "./GroupModal";
import "./Groups.scss";
import { AxiosResponse, deleteGroupByID, getAllGroups } from "./service";
import { State } from "../EmailBook/EmailBook";
import { Alert, Snackbar } from "@mui/material";

export default function Groups() {
  const [open, setOpen] = React.useState(false);
  const [groupListData, setGroupListData] = useState<any[]>([]);
  const [selectedGroupData, setSelectedGroupData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [notificationState, setNotificationState] = useState<State>({
    open: false,
    message: "",
    isError: false,
    vertical: "top",
    horizontal: "center",
    severity: "success",
  });

  useEffect(() => {
    reloadData();
  }, []);

  const showNotification = (message: string, isError: boolean) => {
    setNotificationState({
      ...notificationState,
      open: true,
      message,
      isError,
      severity: isError ? "error" : "success",
    });
  };

  const closeNotification = () => {
    setNotificationState({
      ...notificationState,
      open: false,
      message: "",
      isError: false,
    });
  };

  const reloadData = async () => {
    setLoading(true);
    const response: AxiosResponse = await getAllGroups({
      page: 0,
      limit: 100,
    });

    if (!response.error) {
      await setGroupListData(response.data);
    }

    setLoading(false);
  };

  const handleModalOpen = (data: any) => {
    setOpen(true);
    if (data) {
      const rowData = {
        group_id: data._id,
        name: data.name,
      };

      setSelectedGroupData(rowData);
    } else {
      setSelectedGroupData(null);
    }
  };
  const handleModalClose = () => {
    setOpen(false);
    setSelectedGroupData(null);
  };

  const deleteGroup = async (id: any) => {
    const response: AxiosResponse = await deleteGroupByID({
      group_id: id,
    });
    if (!response.error) {
      showNotification("Group  deleted sucessfully.", false);
      reloadData();
    } else {
      showNotification(response.error.status, true);
      reloadData();
    }
  };

  return (
    <div className="email-config-area">
      <GroupModal
        data={selectedGroupData}
        reloadData={reloadData}
        showNotification={showNotification}
        handleModalOpen={handleModalOpen}
        handleModalClose={handleModalClose}
        isOpen={open}
      />
      <GroupList
        deleteGroup={deleteGroup}
        groupListData={groupListData}
        handleModalOpen={handleModalOpen}
        loading={loading}
      />

      <Snackbar
        anchorOrigin={{
          vertical: notificationState.vertical,
          horizontal: notificationState.horizontal,
        }}
        open={notificationState.open}
        onClose={closeNotification}
        autoHideDuration={6000}
      >
        <Alert
          onClose={closeNotification}
          severity={notificationState.severity}
          sx={{ width: "100%" }}
        >
          {notificationState.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
