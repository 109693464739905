import "./AttributeForm.scss";
import TextInput from "./Utils/TextInput";
import { Button } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

interface MenuItemsFormProps {
  items: any[];
  onChange: (items: any) => void;
  addMenuItem: () => void;
  deleteItem: (item: any) => void;
}
const MenuItemsForm = ({
  items,
  onChange,
  addMenuItem,
  deleteItem,
}: MenuItemsFormProps) => {
  const onMenuItemChange = (item: any) => {
    onChange(item);
  };
  return (
    <div className="attribute-form--field">
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <div className="attribute-form--field-label">Items</div>
        <Button variant="contained" component="label" onClick={addMenuItem}>
          Add Menu Item
        </Button>
      </div>
      {items.map((item: any, index: number) => {
        return (
          <div
            className="attribute-form--field-label"
            key={item.id}
            style={{
              width: "100%",
              padding: "10px",
              marginTop: "10px",
              border: "1px solid rgba(0,0,0,0.12)",
            }}
          >
            <div style={{ width: "100%" }}>
              <span>{`Item ${index + 1}`}</span>
              <span style={{ float: "right" }}>
                <DeleteForeverIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteItem(item)}
                />
              </span>
            </div>
            <div style={{ marginBottom: "10px", width: "100%" }}>
              <TextInput
                label="Name"
                value={item.name}
                onChange={(newValue: string) =>
                  onMenuItemChange({ ...item, name: newValue })
                }
              />
            </div>
            <div style={{ width: "100%" }}>
              <TextInput
                label="URL"
                value={item.url}
                onChange={(newValue: string) =>
                  onMenuItemChange({ ...item, url: newValue })
                }
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MenuItemsForm;
