import { axiosGet, axiosPost } from "../shared/axiosService";

export type AxiosResponse = {
  data: any[];
  group_id?: any;
  error: any;
};

export const getAllGroups = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("group/all", body);
    if (res.data.status) {
      response.data = res.data.groups;
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};

export const addGroup = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("group/add", body);

    if (res.data.status) {
      response.data = res.data.groups;
      response.group_id = res.data.group_id;
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};

export const saveGroup = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("group/save", body);

    if (res.data.status) {
      response.data = res.data.groups;
      response.group_id = res.data.group_id;
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};

export const getGroupById = async (groupId: string) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosGet(`group/id/${groupId}`);

    if (res.data.status) {
      response.data = res.data.groups;
      response.group_id = res.data.group_id;
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};

export const deleteGroupByID = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("group/delete", body);

    if (res.data.status) {
      response.data = res.data.groups;
      response.group_id = res.data.group_id;
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};
