import { TextField } from "@mui/material";
import "./TextInput.scss";

export interface TextInputProps {
  disabled?: boolean;
  label?: string;
  value: string;
  onChange?: (newValue: string) => void;
  width?: string;
}

const TextInput = ({
  disabled = false,
  label,
  value = "",
  onChange,
  width = "100%",
}: TextInputProps) => {
  return (
    <div className="text-input-field">
      <TextField
        className="text-input-field--input"
        label={label}
        value={value}
        variant="standard"
        fullWidth={true}
        style={{ width: width }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChange?.(event.target.value)
        }
        disabled={disabled}
      />
    </div>
  );
};

export default TextInput;
