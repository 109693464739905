import { axiosGet, axiosPost } from "../shared/axiosService";

export type AxiosResponse = {
  data: any[];
  group_id?: any;
  error: any;
};

export const getSettings = async () => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosGet("user/setting");
    if (res.data.status) {
      response.data = [res.data.settting];
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};

export const saveSettings = async (body: any) => {
  const response: AxiosResponse = {
    data: [],
    error: null,
  };
  try {
    const res: any = await axiosPost("user/save-setting", body);

    if (res.data.status) {
      response.data = res.data.settting;
    } else {
      response.error = res.data.message;
    }
  } catch (error: any) {
    console.error(error);
    response.error = error.message;
  }

  return response;
};
