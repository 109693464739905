import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  FormControl,
  Input,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import "./MediaSelectionModal.scss";
import {
  AxiosResponse,
  getAllIcon,
  getAllImages,
  uploadImage,
} from "../../../service";
import { AccountCircle } from "@mui/icons-material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} className="media-list--title">
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
type MediaSelectionModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  mediaType: string;
  selectImage: (imageUrl: string) => void;
};
const MediaSelectionModal = ({
  isOpen,
  handleClose,
  mediaType,
  selectImage,
}: MediaSelectionModalProps) => {
  const [mediaList, setMediaList] = useState<any[]>([]);
  const [iconList, setIconList] = useState<any[]>([]);
  const [filteredIconList, setFilteredIconList] = useState<any[]>([]);
  const [s3Path, setS3Path] = useState<string | undefined>("");
  const [searchText, setSearchText] = useState<string>("");
  const [timer, setTimer] = useState<any>();
  const onClose = () => {
    handleClose();
  };

  useEffect(() => {
    if (isOpen) {
      mediaType === "icon" ? reloadIconData() : reloadImagesData();
    }
  }, [isOpen, mediaType]);

  const onSelectMedia = (mediaUrl: string) => {
    selectImage(mediaUrl);
    handleClose();
  };
  const reloadImagesData = async () => {
    const response: AxiosResponse = await getAllImages();

    if (!response.error) {
      await setMediaList(response.data);
      await setS3Path(response.s3path);
    } else {
    }
  };

  const reloadIconData = async () => {
    const response: AxiosResponse = await getAllIcon();

    if (!response.error) {
      await setIconList(response.data);
      await setS3Path(response.s3path);
      await setFilteredIconList(response.data);
    } else {
    }
  };

  const handleChange = async (event: any) => {
    const response = await uploadImage(event.target.files[0]);
    if (!response.error && response.downloadUrl) {
      selectImage(response.downloadUrl);
      handleClose();
    } else {
    }
  };

  const handleSearch = (event: any) => {
    const searchTextValue = event.target.value;
    setSearchText(searchTextValue);

    clearTimeout(timer);

    const newTimer = setTimeout(async () => {
      let newList = iconList;
      if (searchText) {
        newList = await iconList.reduce((accumulator: any[], item: any) => {
          const newItems = item.icons.filter((name: string) => {
            return name.includes(searchTextValue);
          });

          return newItems.length > 0
            ? [...accumulator, { name: item.name, icons: newItems }]
            : accumulator;
        }, []);
      }
      setFilteredIconList(newList);
    }, 500);

    setTimer(newTimer);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="media-list-modal"
        open={isOpen}
        className="media-list-modal"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
          <div className="media-list-modal--title">
            Media List
            {mediaType === "icon" && (
              <FormControl variant="standard" sx={{ width: "50%" }}>
                <Input
                  id="input-with-icon-adornment"
                  placeholder="Search"
                  onChange={handleSearch}
                  value={searchText}
                  startAdornment={
                    <InputAdornment position="start">
                      <ImageSearchOutlinedIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            )}
          </div>
          <div className="upload-button">
            <Tooltip title="Upload Image">
              <IconButton
                className="upload-button--icon"
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  hidden
                  accept=".png,.jpeg,.jpg,.svg"
                  type="file"
                  onChange={handleChange}
                />
                <FileUploadOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div className="media-list">
            <div className="media-list--area">
              {mediaType === "icon"
                ? (filteredIconList.length > 10
                    ? filteredIconList.slice(0, 3)
                    : filteredIconList
                  ).map((item: any) => (
                    <>
                      <div className="media-list--area-icon-list">
                        <div className="media-list--area-icon-list-title">
                          {item.name}
                        </div>
                        <div className="media-list--area-icon-list-items">
                          {item?.icons.map((media: any, index: number) => (
                            <Paper
                              className={`media-list--item media-list-${mediaType}`}
                              variant="outlined"
                            >
                              <div
                                className={`media-list--item-${mediaType}`}
                                onClick={() =>
                                  onSelectMedia(`${s3Path}/${media}`)
                                }
                              >
                                <img
                                  src={`${s3Path}/${media}`}
                                  alt={`${index}`}
                                  width={100}
                                />
                              </div>
                            </Paper>
                          ))}
                        </div>
                      </div>
                    </>
                  ))
                : mediaList.slice(0, 200).map((media: any, index) => (
                    <Paper
                      className={`media-list--item media-list-image`}
                      variant="outlined"
                    >
                      <div
                        className={`media-list--item-image`}
                        onClick={() => onSelectMedia(`${s3Path}/${media}`)}
                      >
                        <img
                          src={`${s3Path}/${media}`}
                          alt={`${index}`}
                          width={100}
                        />
                      </div>
                    </Paper>
                  ))}
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default MediaSelectionModal;
