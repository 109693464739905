import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";

const SupportMenu = ({ anchorElSupport, handleSupportClose }: any) => {
  return (
    <Menu
      className="support-menu"
      sx={{ mt: "50px" }}
      id="menu-appbar"
      anchorEl={anchorElSupport}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElSupport)}
      onClose={handleSupportClose}
    >
      <MenuItem
        onClick={() => {
          handleSupportClose("");
        }}
      >
        <ListItemIcon>
          <SupportAgentOutlinedIcon fontSize="small" />
        </ListItemIcon>
        Support & Contact
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleSupportClose("");
        }}
      >
        <ListItemIcon>
          <IntegrationInstructionsOutlinedIcon fontSize="small" />
        </ListItemIcon>
        API Documentation
      </MenuItem>
    </Menu>
  );
};

export default SupportMenu;
