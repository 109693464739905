import { Button, TextField } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { read, utils } from "xlsx";
import GroupSelect from "../Groups/GroupSelect";
import EmailBooklist from "./EmailBooklist";
import { validateContacts } from "./utils";

export type EmailBookFormProps = {
  selectedGroup: any[];
  onGroupChange?: (value: any) => void;
  contactData: any[];
  isImport: boolean;
  onValueChange: (value: string) => void;
  reloadGroupData: () => void;
  groupList: any[];
};
const EmailBookForm = ({
  selectedGroup,
  onGroupChange,
  contactData,
  onValueChange,
  isImport,
  reloadGroupData,
  groupList,
}: EmailBookFormProps) => {
  const [contactDataString, setContactDataString] = useState("");
  const onContactDataStringChange = (value: string) => {
    setContactDataString(value);
    onValueChange(value);
  };

  const columns: GridColDef[] = [
    { field: "firstName", headerName: "First name", width: 150 },
    { field: "lastName", headerName: "Last name", width: 150 },
    { field: "email", headerName: "Email", flex: 1, minWidth: 200 },
    { field: "phone", headerName: "Phone No", width: 200 },
  ];
  return (
    <div className="email-add-form">
      <div className="email-add-form--input">
        <GroupSelect
          groupList={groupList}
          reloadGroupData={reloadGroupData}
          values={selectedGroup}
          onGroupChange={onGroupChange}
        />
      </div>

      {!isImport && (
        <div className="email-add-form--input">
          <TextField
            id="outlined-multiline-static"
            label="Contacts"
            multiline
            rows={6}
            fullWidth
            value={contactDataString}
            onChange={(event) => onContactDataStringChange(event.target.value)}
          />
        </div>
      )}

      <div className="email-add-form--list">
        <EmailBooklist contactsData={contactData} columns={columns} />
      </div>
    </div>
  );
};

export default EmailBookForm;
