import React from "react";

export const ImageTag = React.memo(({ src, altText, styleData }: any) => {
  return (
    <img style={{ ...styleData, maxWidth: "100%" }} src={src} alt={altText} />
  );
});

const ImageComponent = ({ properties, style }: any) => {
  const TextDivStyle = {
    paddingTop: `${style.paddingTop}px`,
    paddingRight: `${style.paddingRight}px`,
    paddingBottom: `${style.paddingBottom}px`,
    paddingLeft: `${style.paddingLeft}px`,
    marginTop: `${style.marginTop}px`,
    marginRight: `${style.marginRight}px`,
    marginBottom: `${style.marginBottom}px`,
    marginLeft: `${style.marginLeft}px`,
    textAlign: style.textAlign,
    backgroundColor: style.backgroundColor,
    color: style.color,
    border: `${style.borderSize}px ${style.borderType} ${style.borderColor}`,
    zIndex: style.zIndex,
  };

  const ImageStyle = {
    width: `${style.width}%`,
  };

  if (!properties?.src) {
    return (
      <div style={{ ...TextDivStyle }}>
        <img style={{ width: "100%" }} src="/images/add-image.png" alt="Add" />
      </div>
    );
  }
  return (
    <div style={{ ...TextDivStyle, position: "relative" }}>
      {properties?.mediaType === "icon" ? (
        <ImageTag
          src={properties?.src}
          altText={properties.altText}
          styleData={ImageStyle}
        />
      ) : (
        <ImageTag
          src={properties?.src}
          altText={properties.altText}
          styleData={ImageStyle}
        />
      )}
    </div>
  );
};

export default ImageComponent;
