import { Button } from "@mui/material";
import "./TextAlignInput.scss";
import FormatAlignLeftRoundedIcon from "@mui/icons-material/FormatAlignLeftRounded";
import FormatAlignRightRoundedIcon from "@mui/icons-material/FormatAlignRightRounded";
import FormatAlignJustifyRoundedIcon from "@mui/icons-material/FormatAlignJustifyRounded";
import FormatAlignCenterRoundedIcon from "@mui/icons-material/FormatAlignCenterRounded";

export interface NumberInputProps {
  label?: string;
  value: string;
  onChange: (newValue: TEXT_ALIGN) => void;
}

export enum TEXT_ALIGN {
  LEFT = "left",
  JUSTIFY = "justify",
  CENTER = "center",
  RIGHT = "right",
}

const TextAlignInput = ({ label, value, onChange }: NumberInputProps) => {
  return (
    <div className="text-align-filed">
      {label && <label className="text-align-filed--label">{label}</label>}
      <div className="text-align-filed--content">
        <div
          className={`text-align-filed--button ${
            value === TEXT_ALIGN.LEFT && "active"
          }`}
        >
          <Button onClick={() => onChange(TEXT_ALIGN.LEFT)}>
            <FormatAlignLeftRoundedIcon />
          </Button>
        </div>
        <div
          className={`text-align-filed--button ${
            value === TEXT_ALIGN.CENTER && "active"
          }`}
        >
          <Button onClick={() => onChange(TEXT_ALIGN.CENTER)}>
            <FormatAlignCenterRoundedIcon />
          </Button>
        </div>
        <div
          className={`text-align-filed--button ${
            value === TEXT_ALIGN.RIGHT && "active"
          }`}
        >
          <Button onClick={() => onChange(TEXT_ALIGN.RIGHT)}>
            <FormatAlignRightRoundedIcon />
          </Button>
        </div>
        <div
          className={`text-align-filed--button ${
            value === TEXT_ALIGN.JUSTIFY && "active"
          }`}
        >
          <Button onClick={() => onChange(TEXT_ALIGN.JUSTIFY)}>
            <FormatAlignJustifyRoundedIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TextAlignInput;
