import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../shared/ConfirmationDialog";
import { Box } from "@mui/system";

export type EmailConfiglistProps = {
  emailConfigData: any[];
  handleModalOpen: (data: any) => void;
  deleteEmailConfig: (id: any) => void;
};

const EmailConfiglist = ({
  emailConfigData,
  handleModalOpen,
  deleteEmailConfig,
}: EmailConfiglistProps) => {
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell component="th">Smtp Server</TableCell>
            <TableCell component="th" align="right">
              Port
            </TableCell>
            <TableCell component="th" align="right">
              SSL / TSL
            </TableCell>
            <TableCell component="th" align="right">
              User Name
            </TableCell>
            <TableCell component="th" align="right">
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {emailConfigData.length === 0 && (
            <TableRow>
              <TableCell
                scope="row"
                colSpan={5}
                align="center"
                className="no-data"
              >
                <img
                  src="/images/no-data.png"
                  alt="No data Present"
                  className="no-data-image"
                />
              </TableCell>
            </TableRow>
          )}
          {emailConfigData.map((row) => {
            const { smtp } = row;
            return (
              <TableRow
                key={smtp?._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row">{smtp?.host}</TableCell>
                <TableCell align="right">{smtp?.port || 0}</TableCell>
                <TableCell align="right">{smtp?.ssl ? "SSL" : "TSL"}</TableCell>
                <TableCell align="right">{smtp?.username}</TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="edit"
                    onClick={() => handleModalOpen(row)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => setConfirmationDialogOpen(true)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <ConfirmationDialog
                    desription="Are you sure you want to delete this email config"
                    isOpen={isConfirmationDialogOpen}
                    handleClose={() => setConfirmationDialogOpen(false)}
                    handleOnAgree={() => {
                      deleteEmailConfig(row._id);
                      setConfirmationDialogOpen(false);
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EmailConfiglist;
